import { Space, Table, Modal, Input } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { addExercise, getExercises } from "../../API";

const Exercises = () => {

    const [loading, setLoading] = useState(false);
    const [showExerciseModal, setShowExerciseModal] = useState(false);
    const [exerciseName, setExerciseName] = useState("");
    const [exerciseType, setExerciseType] = useState("");
    const [exercisesList, setExercisesList] = useState([]);
    function EmptyView() {
        return (
            <Space>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </Space>
        )
    }

    const AddExerciseData = () => {
        if (exerciseName === "") {
            alert("Exercise name not entered.")
        } else if (exerciseType === "") {
            alert("Exercise type not entered.")
        } else {
            addExercise(exerciseName, exerciseType, exerciseName, exerciseName)
            .then(res => {
                alert("Exercise Added Successfully.");
                setShowExerciseModal(false);
                getExercisesList();
            })
        }
    }

    const getExercisesList = () => {
        getExercises()
        .then(res => {
            setExercisesList(res.Exercises);
        })
    }

    useEffect(() => {
        getExercisesList();
    }, [])

    return (
        <>
            <Space size={20} direction="vertical" align="start">
                <button className="btn btn-danger mt-3" onClick={() => {
                    setExerciseName("");
                    setExerciseType("");
                    setShowExerciseModal(true);
                }}>Add Exercise</button>
                <Space direction="horizontal">
                    <Table
                        columns={[
                            {
                                title: "Exercise Name",
                                dataIndex: "name"
                            },
                            {
                                title: "Type",
                                dataIndex: "type"
                            },
                            {
                                title: "",
                                dataIndex: "name",
                                render: (value, data) => <button className="btn btn-danger" onClick={() => {
                                    //navigate('/customerorders')
                                    setExerciseName(data.Name);
                                    setExerciseType(data.Type);
                                    setShowExerciseModal(true);
                                }}>Edit Item</button>
                            },
                        ]}
                        loading={loading}
                        dataSource={exercisesList}
                        pagination={{
                            pageSize: 5
                        }}>

                    </Table>
                    <EmptyView />
                    <EmptyView />
                    <EmptyView />
                    <EmptyView />
                </Space>
                <Modal
                    visible={showExerciseModal}
                    okText="Save"
                    closable={false}
                    cancelText="Close"
                    onOk={() => {
                        setExerciseName("");
                        setExerciseType("");
                        AddExerciseData()
                    }}
                    onCancel={() => {
                        setShowExerciseModal(false);
                    }}>
                    <div>
                        <div className="mb-3">
                            <Space direction="vertical">
                                <label className="form-label" htmlFor="stock">
                                    Set Exercise Name
                                </label>
                                <Input value={exerciseName} className="form-control" id="stock" required onChange={(evt) => {
                                    //setEditedPrice(evt.target.value) 
                                    setExerciseName(evt.target.value)
                                }} />
                                <label className="form-label" htmlFor="stock">
                                    Set Exercise Type
                                </label>
                                <Input value={exerciseType} className="form-control" id="stock" required onChange={(evt) => {
                                    //setEditedPrice(evt.target.value) 
                                    setExerciseType(evt.target.value)
                                }} />
                            </Space>
                        </div>
                    </div>
                </Modal>
            </Space>
        </>
    )
}

export default Exercises;