import { Dropdown, Menu, Space, Input, Modal, AutoComplete, Table, Card } from "antd";
import React, { useEffect, useState } from "react";
import { addProducts, getProducts } from "../../API";
import { useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";

const AddDishes = () => {

    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState("");
    const [showProductsModal, setShowProductsModal] = useState(false);
    const [products, setProducts] = useState([]);
    const [dishProducts, setDishProducts] = useState([]);
    const [selectedQuantity, setSelectedQuantity] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [dishName, setDishName] = useState('');
    const [productType, setProductType] = useState('');
    const [protein, setProtein] = useState('');
    const [carbs, setCarbs] = useState('');
    const [fats, setFats] = useState('');
    const [fibers, setFibers] = useState('');
    const [calories, setCalories] = useState('');
    const [unit, setUnit] = useState('');
    const [receipe, setReceipe] = useState('')
    const [isMobile, setIsMobile] = useState(false)

    const handleTypeClick = ({ key }) => {
        setProductType(key)
        //you can perform setState here
    }

    const category = (
        <Menu onClick={handleTypeClick}>
            <Menu.Item key="Veg">Veg</Menu.Item>
            <Menu.Item key="Non-Veg">Non-Veg</Menu.Item>
        </Menu>
    );

    function EmptyView() {
        return (
            <Space>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </Space>
        )
    }

    const getTotalProtiensCount = (products) => {
        var tempCount = 0.0;
        for (var i = 0; i < products.length; i++) {
            tempCount = tempCount + (parseFloat(products[i].protein) * parseFloat(products[i].quantity));
        }
        return parseFloat(tempCount);
    }

    const getTotalFibersCount = (products) => {
        var tempCount = 0.0;
        for (var i = 0; i < products.length; i++) {
            tempCount = tempCount + (parseFloat(products[i].fiber) * parseFloat(products[i].quantity));
        }
        return parseFloat(tempCount);
    }

    const getTotalCaloriesCount = (products) => {
        var tempCount = 0.0;
        for (var i = 0; i < products.length; i++) {
            tempCount = tempCount + (parseFloat(products[i].calories) * parseFloat(products[i].quantity));
        }
        return parseFloat(tempCount);
    }

    const getTotalCarbsCount = (products) => {
        var tempCount = 0.0;
        for (var i = 0; i < products.length; i++) {
            tempCount = tempCount + (parseFloat(products[i].carbs) * parseFloat(products[i].quantity));
        }
        return parseFloat(tempCount);
    }

    const getTotalFatsCount = (products) => {
        var tempCount = 0.0;
        for (var i = 0; i < products.length; i++) {
            tempCount = tempCount + (parseFloat(products[i].fats) * parseFloat(products[i].quantity));
        }
        return parseFloat(tempCount);
    }

    const AddDishData = () => {
        if (dishName === "") {
            alert("Dish Name is not mentioned.")
        } else if (productType === "") {
            alert("Dish Type is not mentioned.")
        } else if (receipe === "") {
            alert("Dish receipe is not entered.")
        } else if (dishProducts === 0) {
            alert("Please add product.");
        } else {
            addProducts(dishName, productType, getTotalProtiensCount(dishProducts), getTotalCarbsCount(dishProducts), getTotalFatsCount(dishProducts), getTotalFibersCount(dishProducts), getTotalCaloriesCount(dishProducts), dishName, dishName, unit, JSON.stringify(dishProducts), receipe)
                .then(res => {
                    alert("Product Added Successfully.")
                    navigate('/productlist')
                })
        }
    }

    useEffect(() => {
        var isMobile = reactLocalStorage.get("isMobile");
        if (isMobile === "true") {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
        getProducts()
            .then(res => {
                setProducts(res.Products)
            })
    }, [])

    const AddProductToDishes = (product, amount) => {
        var tempDishes = [...dishProducts]
        var tempDishProduct = {
            itemname: product.itemname,
            type: product.type,
            quantity: amount,
            protein: product.protein,
            fiber: product.fiber,
            fats: product.fats,
            carbs: product.carbs,
            calories: product.calories,
        }
        tempDishes.push(tempDishProduct);
        setDishProducts(tempDishes)
    }

    const RemoveProduct = (index) => {
        let tempDishes = [...dishProducts]
        tempDishes.splice(index, 1)
        setDishProducts(tempDishes);
    }

    return (
        <>
            <Space direction="vertical">
                <button className="btn btn-danger" onClick={() => {
                    //removeProduct(parseInt(data.id) - 1, index)
                    navigate('/')
                }}>To Dashboard</button>
                <Space direction="horizontal">
                    <div className="container mt-2" style={{ flex: 1, minWidth: '300px' }}>
                        <h2 className="mb-3">Add Product Details</h2>
                        {/* <form onSubmit={onSubmit}> */}
                        <Space direction="vertical">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="title">
                                    Dish Name
                                </label>
                                <Input value={dishName} className="form-control" id="quantity" required onChange={(evt) => { setDishName(evt.target.value) }} />
                            </div>
                            <div className="mb-2">
                                <label className="form-label" htmlFor="stock">
                                    Set Dish Type
                                </label>
                                <Dropdown overlay={category} trigger={["click"]}>
                                    <a
                                        style={{ height: 42 }}
                                        className="form-control"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        {productType}
                                    </a>
                                </Dropdown>
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="title">
                                    Enter Receipe
                                </label>
                                <Input value={receipe} className="form-control" id="quantity" required onChange={(evt) => { setReceipe(evt.target.value) }} />
                            </div>
                            {/* 
                            <div className="mb-3">
                                <label className="form-label" htmlFor="price">
                                    Protein
                                </label>
                                <Input value={protein} className="form-control" type="number" id="quantity" required onChange={(evt) => { setProtein(evt.target.value) }} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="price">
                                    Carbs
                                </label>
                                <Input value={carbs} className="form-control" type="number" id="quantity" required onChange={(evt) => { setCarbs(evt.target.value) }} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="price">
                                    Fats
                                </label>
                                <Input value={fats} className="form-control" type="number" id="quantity" required onChange={(evt) => { setFats(evt.target.value) }} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="price">
                                    Fibers
                                </label>
                                <Input value={fibers} className="form-control" type="number" id="quantity" required onChange={(evt) => { setFibers(evt.target.value) }} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="price">
                                    Calories
                                </label>
                                <Input value={calories} className="form-control" type="number" id="quantity" required onChange={(evt) => { setCalories(evt.target.value) }} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="price">
                                    Unit
                                </label>
                                <Input value={unit} className="form-control" id="quantity" required onChange={(evt) => { setUnit(evt.target.value) }} />
                            </div> */}
                            <button className="btn btn-danger mt-1 mb-1" onClick={() => {
                                AddDishData()
                            }}>Add Dish</button>
                            <button className="btn btn-danger mt-1 mb-1" onClick={() => {
                                setShowProductsModal(true)
                            }}>Add More Products To Dish</button>
                            <h4 className="mb-3 mt-3">Added Products Details</h4>
                            {!isMobile ?
                                <Table
                                    columns={[
                                        {
                                            title: "Product Name",
                                            dataIndex: "itemname"
                                        },
                                        {
                                            title: "Type",
                                            dataIndex: "type"
                                        },
                                        {
                                            title: "Quantity",
                                            dataIndex: "quantity"
                                        },
                                        {
                                            title: "Protein",
                                            dataIndex: "protein",
                                            render: (value, data, index) => <p style={{ marginTop: 13 }}>{parseFloat(data.protein) * parseFloat(data.quantity)}</p>
                                        },
                                        {
                                            title: "Fiber",
                                            dataIndex: "fiber",
                                            render: (value, data, index) => <p style={{ marginTop: 13 }}>{parseFloat(data.fiber) * parseFloat(data.quantity)}</p>
                                        },
                                        {
                                            title: "Fats",
                                            dataIndex: "fats",
                                            render: (value, data, index) => <p style={{ marginTop: 13 }}>{parseFloat(data.fats) * parseFloat(data.quantity)}</p>
                                        },
                                        {
                                            title: "Carbs",
                                            dataIndex: "carbs",
                                            render: (value, data, index) => <p style={{ marginTop: 13 }}>{parseFloat(data.carbs) * parseFloat(data.quantity)}</p>
                                        },
                                        {
                                            title: "Calories",
                                            dataIndex: "calories",
                                            render: (value, data, index) => <p style={{ marginTop: 13 }}>{parseFloat(data.calories) * parseFloat(data.quantity)}</p>
                                        },
                                        {
                                            title: "",
                                            dataIndex: "itemname",
                                            render: (value, data, insideIndex) => <button className="btn btn-danger" onClick={() => {
                                                //removeProduct(insideIndex, index)
                                                RemoveProduct(insideIndex)
                                            }}>Remove</button>
                                        },
                                    ]}
                                    dataSource={dishProducts}>

                                </Table> :
                                <Space direction="vertical">
                                    {dishProducts.map((item, insideIndex) => {
                                        return (
                                            <div key={item} style={{ borderRadius: "10px" }}>
                                                <Card>
                                                    <Space direction="vertical" style={{ width: 200 }}>
                                                        <label className="form-label" htmlFor="purchasedfrom">
                                                            Name: <strong>{item.itemname}</strong>
                                                        </label>
                                                        <label className="form-label" htmlFor="purchasedfrom">
                                                            Type: <strong>{item.type}</strong>
                                                        </label>
                                                        <label className="form-label" htmlFor="purchasedfrom">
                                                            Quantity: <strong>{item.quantity}</strong>
                                                        </label>
                                                        <label className="form-label" htmlFor="purchasedfrom">
                                                            Protein: <strong>{parseFloat(item.protein) * parseFloat(item.quantity)}</strong>
                                                        </label>
                                                        <label className="form-label" htmlFor="purchasedfrom">
                                                            Carbs: <strong>{parseFloat(item.carbs) * parseFloat(item.quantity)}</strong>
                                                        </label>
                                                        <label className="form-label" htmlFor="purchasedfrom">
                                                            Fats: <strong>{parseFloat(item.fats) * parseFloat(item.quantity)}</strong>
                                                        </label>
                                                        <label className="form-label" htmlFor="purchasedfrom">
                                                            Fiber: <strong>{parseFloat(item.fiber) * parseFloat(item.quantity)}</strong>
                                                        </label>
                                                        <label className="form-label" htmlFor="purchasedfrom">
                                                            Calories: <strong>{parseFloat(item.calories) * parseFloat(item.quantity)}</strong>
                                                        </label>
                                                        <button className="btn btn-danger" onClick={() => {
                                                            //removeProduct(parseInt(data.id) - 1, index)
                                                            RemoveProduct(insideIndex)
                                                        }}>Remove</button>
                                                    </Space>
                                                </Card>
                                            </div>
                                        )
                                    })}
                                </Space>
                            }
                            {/* <button className="btn btn-danger mb-5" onClick={() => AddProductData()}>
                                Add Product
                            </button> */}
                        </Space>

                    </div>
                    <EmptyView />
                    <EmptyView />
                    <EmptyView />
                    <EmptyView />
                </Space>
                <Modal
                    visible={showProductsModal}
                    okText="Save"
                    closable={false}
                    cancelText="Close"
                    onOk={() => {
                        //AddFoodToMeal(selectedIndex, selectedQuantity, selectedProduct)
                        AddProductToDishes(selectedProduct, selectedQuantity)
                        setShowProductsModal(false);
                    }}
                    onCancel={() => {
                        setShowProductsModal(false);
                    }}>
                    <div>
                        <div className="mb-3">
                            <div>
                                <label className="form-label" htmlFor="purchasedfrom">
                                    Search By Food Name
                                </label>
                                <AutoComplete
                                    className="form-control"
                                    //value={inputValueCustomer}
                                    options={products}
                                    autoFocus={true}
                                    style={{ width: 200, height: 45, marginBottom: 10 }}
                                    filterOption={(inputValue, option) =>
                                        option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onSelect={(value, data) => {
                                        setSelectedProduct(data)
                                    }}
                                    onChange={() => {
                                        setInputValue("");
                                    }}
                                />
                            </div>
                            <label className="form-label" htmlFor="stock">
                                Enter Amount
                            </label>
                            <Input value={selectedQuantity} className="form-control" type="number" id="stock" required onChange={(evt) => {
                                //setEditedPrice(evt.target.value) 
                                setSelectedQuantity(evt.target.value)
                            }} />
                        </div>
                    </div>
                </Modal>
            </Space>
        </>
    )
}

export default AddDishes;