import logo from './logo.svg';
import './App.css';
import AppHeader from './Components/AppHeader';
import { Image, Space, Modal, Input } from "antd";
import SideMenu from './Components/SideMenu';
import PageContent from './Components/PageContent';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { useEffect, useState } from 'react';
import { addEnquiry, getTrainerByLogin } from './API';
import { reactLocalStorage } from 'reactjs-localstorage';
import useWindowDimensions from './Components/useWindowDimensions';

function App() {

  const { height, width } = useWindowDimensions();
  const [showADmin, setShowAdmin] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEnquiryModalVisible, setIsEnquiryModalVisible] = useState(false);
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerMobile, setCustomerMobile] = useState("");
  const [customerPurpose, setCustomerPurpose] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (width > 500) {
      setIsMobile(false)
    } else {
      setIsMobile(true);
    }
  }, [])


  const AddEnquiry = () => {
    if (customerName === "") {
      alert("Please add your proper name.")
    } else if (customerMobile === "" || customerMobile.length < 10) {
      alert("Please enter your proper 10 digit mobile number.")
    } else if (customerPurpose === "" || customerPurpose.length < 10) {
      alert("Please enter your goal in little more details.")
    } else {
      addEnquiry(getCurrentDate(), customerName, customerMobile, customerPurpose)
        .then(res => {
          setIsEnquiryModalVisible(false);
          alert("Your enquiry hase been submitted our trainers will call you soon.")
        })
    }
  }

  const getCurrentDate = () => {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return date + "/" + month + "/" + year
  }

  const checkLogin = () => {
    if (mobile.trim() === "") {
      alert("Please enter proper username")
    } else if (password.trim() === "") {
      alert("Please enter proper password")
    } else {
      getTrainerByLogin(mobile, password)
        .then(res => {
          if (res.Trainers.length === 0) {
            alert("Wrong Username or Password.")
          } else {
            setShowAdmin(true)
            reactLocalStorage.set("workout", "");
            reactLocalStorage.set("meals", "")
            reactLocalStorage.set("instructions", "")
            reactLocalStorage.set("id", res.Trainers[0].id)
            reactLocalStorage.set("trainername", res.Trainers[0].name)
          }
        })
    }
  }

  const setDeviceIsNotMobile = () => {
    reactLocalStorage.set("isMobile", "false")
  }
  
  const setDeviceIsMobile = () => {
    reactLocalStorage.set("isMobile", "true")
  }

  if (showADmin === false) {
    return (
      <html lang="en">
        <head>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>Starbucks Landing Page</title>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap"
            rel="stylesheet"
          />
          <link rel="stylesheet" href="style.css" />
          <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato" />
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        </head>
        <body>
          <div class="container">
            <div class="navbar">
              <div class="navbar__logo">
                <Image
                  preview={false}
                  style={{ marginLeft: 50, marginTop: 20 }}
                  width={250}
                  src="https://bodygoalsapp.com/Images/bodygoalslogoone.png" />
              </div>
              <div class="navbar-menu__wrapper">
                <div class="navbar__menu">
                  <a href="#" class="active"> Home </a>
                  {/* <a href="https://farmeasy.co.in/refundpolicy.html"> Refund Policy </a>
                  <a href="https://farmeasy.co.in/privacypolicy.html"> Privacy Policy </a>
                  <a href="https://farmeasy.co.in/cancellationpolicy.html"> Cancellation Policy </a>
                  <a href="https://farmeasy.co.in/termsandconditions.html"> Terms and Conditions </a>
                  <a href="https://farmeasy.co.in/shippingpolicy.html"> Cancellation Policy </a> */}
                </div>
                <div class="navbar__auth">
                  <button onClick={() => {
                    setIsModalVisible(true)
                    setDeviceIsNotMobile();
                    }} class="sign-in">Admin Login</button>
                </div>
              </div>
            </div>
            <div class="hero">
              <div class="hero__content">
                <Space style={{ marginTop: 30 }}>
                  <div class="snippets">
                    <span> Welcome To </span>
                  </div>
                </Space>
                <div class="title">
                  <span>
                    <span class="summer">Body Goals App</span>
                  </span>
                </div>
                <div class="title">
                  <span>
                    Get Ready for Change to Happen
                  </span>
                </div>
                <div class="description">
                  <span>
                    Get Systematic plan for your goals that will help you achieve your dream body.
                  </span>
                </div>

                <button class="order-now" onClick={() => setIsEnquiryModalVisible(true)}>Enroll Now</button>
                {isMobile ? <button class="order-now" onClick={() => {
                    setIsModalVisible(true)
                    setDeviceIsMobile()
                  }}>Trainer Login</button> : <div></div>}
              </div>
              <div class="hero__image">
                <Image
                  preview={false}
                  //width={700}
                  src="https://bodygoalsapp.com/Images/20944480.jpg" />
              </div>
            </div>
            {/* <div class="title">
              <span>
                About Us
              </span>
            </div>
            <div class="descriptionaboutus">
              <span>
                FamEasy Natural & Fresh company is working since 2023. The only director of the company is Nishant Avhad. He established this company with the help of his father and is running it well.
              </span>
            </div>
            <div class="descriptionaboutus">
              <span>
                FarmEasy is B2B business Model. FarmEasy is a digital platform joined by many farmers as well as many commercial traders , hotel owners and food processing companies who require large quantities of fruits and vegetables. FarmEasy is providing 2 or 3 quality of per vegetables and fruits for customers. so customer can choose suitable for him the quality and buy it easily . These purchased goods are delivered at home within a very short time. FarmEasy became the link between the farmer and this company.
              </span>
            </div>
            <div class="descriptionaboutus">
              <span>
                FarmEasy accepts both cash and online payment cards But does not work on credit.
              </span>
            </div> */}
            <Space direction="horizontal" style={{ marginBottom: 50 }}>
              <div class="w3-row-padding">
                <div class="w3-row-padding w3-center">
                  <div class="w3-third">
                    <div class="w3-container">
                      <Image
                        style={{ marginTop: 50 }}
                        preview={false}
                        width={300}
                        src="https://bodygoalsapp.com/Images/Sandy_F&D-04_Single-06.jpg" />
                      <h2>Diet Plans</h2><br />
                      <p>Our Customized Diet Plans are created by Certified Nutritionist keeping in my mind your health and favourite things.</p>
                    </div>
                  </div>

                  <div class="w3-third">
                    <div class="w3-container">
                      <h2></h2><br />
                      <h2></h2><br />
                      <Image
                        preview={false}
                        width={250}
                        marginVertical={25}
                        src="https://bodygoalsapp.com/Images/3653074.jpg" />
                      <h2></h2><br />
                      <p></p>
                      <h2>Workout Plans</h2><br />
                      <p>We provide Customized Workout Plans that will suit all your body related goals and improve your health.</p>
                      <p></p>
                    </div>
                  </div>

                  <div class="w3-third">
                    <div class="w3-container">
                      <h2></h2><br />
                      <h2></h2><br />
                      <Image
                        preview={false}
                        width={250}
                        src="https://bodygoalsapp.com/Images/3529885.jpg" />
                      <h2></h2><br />
                      <h2>Regular Followup</h2><br />
                      <p>We keep regular follow up of our clients to know their progress and keep guiding them on their journey.</p>
                    </div>
                  </div>
                </div>
              </div>
            </Space>

            <Space style={{ marginTop: 100 }}>
              <footer class="footer-distributed">
                <div class="footer-left">

                  <h3>Body<span>Goals</span></h3>

                  <p class="footer-links">
                    {/* <a href="#" class="link-1">Home</a> */}

                    {/* <a href="https://farmeasy.co.in/refundpolicy.html">Refund Policy</a>

                    <a href="https://farmeasy.co.in/privacypolicy.html">Privacy Policy</a>

                    <a href="https://farmeasy.co.in/cancellationpolicy.html">Cancellation Policy</a>

                    <a href="https://farmeasy.co.in/termsandconditions.html">Terms and Conditions</a>

                    <a href="https://farmeasy.co.in/shippingpolicy.html">Shipping Policy</a> */}
                  </p>

                  <p class="footer-company-about">
                    <span>Company Profile</span>
                    <p>Body Goals App is a moment to help people achieve their dream physique through workout plans, diet plans and body monitoring. It will help people get fit physically which will lead to better lifestyle and health. </p>
                  </p>

                  <Space direction="horizontal" style={{ height: 100 }}>
                    <div>
                      <i class="fa fa-map-marker"></i>
                    </div>
                    <div>
                      <span>Address - </span>
                      <p><span>203 Rangashree, </span> Veer Savarkar Path, Near Teen Petrol Pump, Ghantali, Thane - 400602</p>
                    </div>
                  </Space>


                  {/* <div>
                    <i class="fa fa-envelope"></i>
                    <p><a href="mailto:support@company.com">support@company.com</a></p>
                  </div> */}

                  <p class="footer-company-name">Body Goals App © 2015</p>
                </div>
              </footer>
            </Space>

          </div>
          <Modal
            visible={isModalVisible}
            okText="Login"
            closable={false}
            cancelText="Close"
            onOk={() => {
              checkLogin()
            }}
            onCancel={() => {
              setIsModalVisible(false);
            }}>
            <div>
              <div className="mb-3">
                <label className="form-label" htmlFor="stock">
                  Enter Mobile
                </label>
                <Input value={mobile} maxLength={10} className="form-control mb-1" id="stock" required onChange={(evt) => { setMobile(evt.target.value) }} />
                <label className="form-label" htmlFor="stock">
                  Enter Password
                </label>
                <Input pattern='password' value={password} className="form-control mb-1" id="stock" required onChange={(evt) => { setPassword(evt.target.value) }} />
              </div>
            </div>
          </Modal>
          <Modal
            visible={isEnquiryModalVisible}
            okText="Submit"
            closable={false}
            cancelText="Close"
            onOk={() => {
              AddEnquiry()
            }}
            onCancel={() => {
              setIsEnquiryModalVisible(false);
            }}>
            <div>
              <div className="mb-3">
                <label className="form-label" htmlFor="stock">
                  Enter Name
                </label>
                <Input value={customerName} className="form-control mb-1" id="stock" required onChange={(evt) => { setCustomerName(evt.target.value) }} />
                <label className="form-label" htmlFor="stock">
                  Enter Mobile
                </label>
                <Input value={customerMobile} maxLength={10} className="form-control mb-1" id="stock" required onChange={(evt) => { setCustomerMobile(evt.target.value) }} />
                <label className="form-label" htmlFor="stock">
                  Enter Your Goal
                </label>
                <Input value={customerPurpose} className="form-control mb-1" id="stock" required onChange={(evt) => { setCustomerPurpose(evt.target.value) }} />
              </div>
            </div>
          </Modal>
        </body>
      </html>
      // <div style={styles.container}>
      //   <header style={styles.header}>
      //     <h1>Welcome to FitLife Gym</h1>
      //   </header>
      //   <main style={styles.main}>
      //     <section style={styles.section}>
      //       <h2>About Us</h2>
      //       <p>
      //         FitLife Gym is a premier fitness center dedicated to helping you achieve your fitness goals.
      //         Our state-of-the-art facility, professional trainers, and variety of fitness programs ensure
      //         that you have all the tools you need for a healthier lifestyle.
      //       </p>
      //     </section>
      //     <section style={styles.section}>
      //       <h2>Our Services</h2>
      //       <ul>
      //         <li>Personal Training</li>
      //         <li>Group Classes</li>
      //         <li>Yoga and Pilates</li>
      //         <li>Cardio and Strength Equipment</li>
      //         <li>Nutrition Counseling</li>
      //       </ul>
      //     </section>
      //     <section style={styles.section}>
      //       <h2>Join Us</h2>
      //       <p>
      //         Ready to start your fitness journey? Visit us today or sign up online to become a member of FitLife Gym.
      //         We look forward to supporting you every step of the way!
      //       </p>
      //     </section>
      //   </main>
      //   <footer style={styles.footer}>
      //     <p>Contact us at: info@fitlifegym.com</p>
      //     <p>&copy; 2024 FitLife Gym</p>
      //   </footer>
      // </div>
    )
  } else {
    return (
      <div className="App">
        <AppHeader />
        <Space className="SideMenuAndPageContent">
          {!isMobile ? <SideMenu></SideMenu> : <div></div>}
          <PageContent></PageContent>
        </Space>
      </div>
    );
  }
}

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
  },
  header: {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '10px 0',
  },
  main: {
    marginTop: '20px',
  },
  section: {
    margin: '20px 0',
  },
  footer: {
    marginTop: '20px',
    borderTop: '1px solid #ddd',
    paddingTop: '10px',
  },
};

export default App;
