import { Dropdown, Menu, Space, Input } from "antd";
import React, { useState } from "react";
import { addProducts } from "../../API";
import { useNavigate } from "react-router-dom";

const AddProducts = () => {

    const navigate = useNavigate();
    const [productName, setProductName] = useState('');
    const [productType, setProductType] = useState('');
    const [protein, setProtein] = useState('');
    const [carbs, setCarbs] = useState('');
    const [fats, setFats] = useState('');
    const [fibers, setFibers] = useState('');
    const [calories, setCalories] = useState('');
    const [unit, setUnit] = useState('');

    const handleTypeClick = ({ key }) => {
        setProductType(key)
        //you can perform setState here
    }

    const category = (
        <Menu onClick={handleTypeClick}>
            <Menu.Item key="Veg">Veg</Menu.Item>
            <Menu.Item key="Non-Veg">Non-Veg</Menu.Item>
        </Menu>
    );

    function EmptyView() {
        return (
            <Space>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </Space>
        )
    }

    const AddProductData = () => {
        if (productName === "") {
            alert("Please add product name.")
        } else if (productType === "") {
            alert("Please add product name.")
        } else if (protein === "") {
            alert("Please add product protein content.")
        } else if (carbs === "") {
            alert("Please add product carbs content.")
        } else if (fats === "") {
            alert("Please add product fats content.")
        } else if (fibers === "") {
            alert("Please add product fiber content.")
        } else if (calories === "") {
            alert("Please add product calories content.")
        } else {
            addProducts(productName, productType, protein, carbs, fats, fibers, calories, productName, productName, unit, "", "")
            .then(res => {
                alert("Product Added Successfully.")
                navigate('/productlist')
            })
        }
    }

    return (
        <>
            <Space>
                <Space direction="horizontal">
                    <div className="container mt-5" style={{ flex: 1 }}>
                        <h2 className="mb-3">Add Product Details</h2>
                        {/* <form onSubmit={onSubmit}> */}
                        <Space direction="vertical">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="title">
                                    Product Name
                                </label>
                                <Input value={productName} className="form-control" id="quantity" required onChange={(evt) => { setProductName(evt.target.value) }} />
                            </div>
                            <div className="mb-2">
                                <label className="form-label" htmlFor="stock">
                                    Set Type
                                </label>
                                <Dropdown overlay={category} trigger={["click"]}>
                                    <a
                                        style={{ height: 42 }}
                                        className="form-control"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        {productType}
                                    </a>
                                </Dropdown>
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="price">
                                    Protein
                                </label>
                                <Input value={protein} className="form-control" type="number" id="quantity" required onChange={(evt) => { setProtein(evt.target.value) }} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="price">
                                    Carbs
                                </label>
                                <Input value={carbs} className="form-control" type="number" id="quantity" required onChange={(evt) => { setCarbs(evt.target.value) }} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="price">
                                    Fats
                                </label>
                                <Input value={fats} className="form-control" type="number" id="quantity" required onChange={(evt) => { setFats(evt.target.value) }} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="price">
                                    Fibers
                                </label>
                                <Input value={fibers} className="form-control" type="number" id="quantity" required onChange={(evt) => { setFibers(evt.target.value) }} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="price">
                                    Calories
                                </label>
                                <Input value={calories} className="form-control" type="number" id="quantity" required onChange={(evt) => { setCalories(evt.target.value) }} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="price">
                                    Unit
                                </label>
                                <Input value={unit} className="form-control" id="quantity" required onChange={(evt) => { setUnit(evt.target.value) }} />
                            </div>
                            <button className="btn btn-danger mb-5" onClick={() => AddProductData()}>
                                Add Product
                            </button>
                        </Space>

                    </div>
                    <EmptyView />
                    <EmptyView />
                    <EmptyView />
                    <EmptyView />
                </Space>
            </Space>
        </>
    )
}

export default AddProducts;