import React, { useEffect, useRef, useState } from "react";
import { AutoComplete, Card, DatePicker, Divider, Input, Modal, Space, Table, TimePicker } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Chart } from "react-google-charts";
import { getClientWiseDietPlan, getClientWiseProgress, getClientWiseWorkoutPlans } from "../../API";
import { reactLocalStorage } from 'reactjs-localstorage';

const ClientDetails = () => {

    const navigate = useNavigate();
    const { state } = useLocation();
    const { data } = state;
    const format = 'HH:mm';
    const componentRef = useRef();
    const [clientName, setClientName] = useState("");
    const [clientMobile, setClientMobile] = useState("");
    const [clientGender, setClientGender] = useState("");
    const [clientAge, setClientAge] = useState("");
    const [clientBodyWeight, setClientBodyWeight] = useState("");
    const [clientHeight, setClientHeight] = useState("");
    const [clientProfession, setClientProfession] = useState("");
    const [clientHealthConcern, setClientHealthConcern] = useState("");
    const [clientAllergyRestriction, setClientAllergyRestriction] = useState("");
    const [clientHealthGoal, setClientHealthGoal] = useState("");
    const [clientSleepingTime, setClientSleepingTime] = useState("");
    const [isMobile, setIsMobile] = useState(false);

    const [workouts, setWorkouts] = useState([]);

    const CalculateBMI = (weight, height) => {
        const bmi = parseFloat(weight) / parseFloat(height * height);
        console.log("Weight: " + weight + "Height: " + height);
        return bmi;
    }

    const [clientDetails, setClientDetails] = useState([
        {
            key: "Gender",
            value: data.gender
        },
        {
            key: "age",
            value: data.age
        },
        {
            key: "Body Weight",
            value: data.weight
        },
        {
            key: "Height",
            value: data.height
        },
        {
            key: "Profession",
            value: data.lineofwork
        },
        {
            key: "Health Concern",
            value: data.healthconcern
        },
        {
            key: "Allergy Restriction",
            value: data.allergyrestriction
        },
        {
            key: "Health Goal",
            value: data.healthgoal
        },
        {
            key: "Sleeping Time",
            value: data.sleepingtime
        }
    ])

    const [clientDisplayDetails, setClientDisplayDetails] = useState([data])
    const [clientDisplayDetailsTwo, setClientDisplayDetailsTwo] = useState([data])

    const [dietPlans, setDietPlans] = useState([])

    const [previewMeals, setPreviewMeals] = useState([]);
    const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
    const [isPreviewWorkoutModalVisible, setIsPreviewWorkoutModalVisible] = useState(false);
    const [previewInstructions, setPreviewInstructions] = useState([]);
    const [progressList, setProgressList] = useState([])
    const [selectedBMIDates, setSelectedBMIDates] = useState([]);
    const [selectedBMI, setSelectedBMI] = useState([]);
    const [trainerID, setTrainerID] = useState("");
    const [trainerName, setTrainerName] = useState("");

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const getTotalProtiensCount = (products) => {
        var tempCount = 0.0;
        for (var i = 0; i < products.length; i++) {
            tempCount = tempCount + (parseFloat(products[i].protein) * parseFloat(products[i].quantity));
        }
        return parseFloat(tempCount);
    }

    const getTotalCaloriesCount = (products) => {
        var tempCount = 0.0;
        for (var i = 0; i < products.length; i++) {
            tempCount = tempCount + (parseFloat(products[i].calories) * parseFloat(products[i].quantity));
        }
        return parseFloat(tempCount);
    }

    const getTotalFibersCount = (products) => {
        var tempCount = 0.0;
        for (var i = 0; i < products.length; i++) {
            tempCount = tempCount + (parseFloat(products[i].fiber) * parseFloat(products[i].quantity));
        }
        return parseFloat(tempCount);
    }

    const getTotalCarbsCount = (products) => {
        var tempCount = 0.0;
        for (var i = 0; i < products.length; i++) {
            tempCount = tempCount + (parseFloat(products[i].carbs) * parseFloat(products[i].quantity));
        }
        return parseFloat(tempCount);
    }

    const getTotalFatsCount = (products) => {
        var tempCount = 0.0;
        for (var i = 0; i < products.length; i++) {
            tempCount = tempCount + (parseFloat(products[i].fats) * parseFloat(products[i].quantity));
        }
        return parseFloat(tempCount);
    }

    function EmptyView() {
        return (
            <Space>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </Space>
        )
    }

    useEffect(() => {
        var tempID = reactLocalStorage.get("id");
        var tempName = reactLocalStorage.get("trainername");
        var isMobile = reactLocalStorage.get("isMobile");
        if (isMobile === "true") {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
        setTrainerID(tempID);
        setTrainerName(tempName);
        getClientWiseProgressData(tempID, data.id)
    }, []);

    const getClientWiseProgressData = (trainerID, id) => {
        getClientWiseProgress(trainerID, id)
            .then(res => {
                setProgressList(res.Progress)
                var tempBMIDates = ["Month"];
                var tempBMI = [new Date().getFullYear()]
                for (var i = 0; i < res.Progress.length; i++) {
                    tempBMIDates.push(res.Progress[i].date)
                    tempBMI.push(CalculateBMI(res.Progress[i].weight, parseFloat(res.Progress[i].height * 0.01)))
                }
                setSelectedBMIDates(tempBMIDates);
                setSelectedBMI(tempBMI);
                loadDietPlans(trainerID, id);
            })
    }

    const loadDietPlans = (trainer_id, clientId) => {
        getClientWiseDietPlan(trainer_id, clientId)
            .then(res => {
                setDietPlans(res.DietPlan)
                loadWorkoutData(trainer_id, clientId)
            })
    }

    const loadWorkoutData = (trainerID, clientID) => {
        getClientWiseWorkoutPlans(trainerID, clientID)
            .then(res => {
                console.log(res.Workouts)
                setWorkouts(res.Workouts)
            })
    }

    function BarChart({ dates, bmi }) {

        const data = [
            dates,
            bmi,
        ];

        const options = {
            chart: {
                title: "Monthwise BMI",
            },
        };

        return (
            <Chart
                chartType="Bar"
                width="85%"
                height="250px"
                marginLeft="230px"
                data={data}
            />
        )
    }

    function BarChartSmall({ dates, bmi }) {

        const data = [
            dates,
            bmi,
        ];

        const options = {
            chart: {
                title: "Monthwise BMI",
            },
        };

        return (
            <Chart
                chartType="Bar"
                width="100%"
                height="250px"
                marginLeft="230px"
                data={data}
            />
        )
    }

    return (
        <>
            <button className="btn btn-danger" onClick={() => {
                //removeProduct(parseInt(data.id) - 1, index)
                navigate('/')
            }}>To Dashboard</button>
            <Space direction="horizontal" style={{ marginTop: 20, marginBottom: 20 }}>
                <strong>Client Details</strong>
                <EmptyView />
                <EmptyView />
                <EmptyView />
                <EmptyView />
                <EmptyView />
            </Space>
            {!isMobile ?
                <Space direction="vertical">
                    <Table
                        columns={[
                            {
                                title: "Name",
                                dataIndex: "name"
                            },
                            {
                                title: "Mobile",
                                dataIndex: "mobile"
                            },
                            {
                                title: "Age",
                                dataIndex: "age"
                            },
                            {
                                title: "Gender",
                                dataIndex: "gender"
                            },
                            {
                                title: "Profession",
                                dataIndex: "lineofwork"
                            },
                        ]}
                        dataSource={clientDisplayDetails}
                        pagination={false}>

                    </Table>
                    <Table
                        columns={[
                            {
                                title: "Weight",
                                dataIndex: "weight"
                            },
                            {
                                title: "Height",
                                dataIndex: "height"
                            },
                            {
                                title: "Health Concern",
                                dataIndex: "healthconcern"
                            },
                            {
                                title: "Allergy Restriction",
                                dataIndex: "allergyrestriction"
                            },
                            {
                                title: "Health Goal",
                                dataIndex: "healthgoal"
                            },
                        ]}
                        dataSource={clientDisplayDetailsTwo}
                        pagination={false}>

                    </Table>
                </Space>
                :
                <Space direction="vertical">
                    {clientDisplayDetails.map((item, index) => {
                        return (
                            <Space direction="vertical">
                                <label className="form-label" htmlFor="purchasedfrom">
                                    Name: <strong>{item.name}</strong>  |  Mobile: <strong>{item.mobile}</strong>
                                </label>
                                <label className="form-label" htmlFor="purchasedfrom">
                                    Age: <strong>{item.age}</strong>  |  Gender: <strong>{item.gender}</strong>
                                </label>
                                <label className="form-label" htmlFor="purchasedfrom">
                                    Profession: <strong>{item.lineofwork}</strong>
                                </label>
                            </Space>
                        )
                    })}
                    {clientDisplayDetailsTwo.map((item, index) => {
                        return (
                            <Space direction="vertical">
                                <label className="form-label" htmlFor="purchasedfrom">
                                    Weight: <strong>{item.weight}</strong>  |  Height: <strong>{item.height}</strong>
                                </label>
                                <label className="form-label" htmlFor="purchasedfrom">
                                    Health Concern: <strong>{item.healthconcern}</strong>
                                </label>
                                <label className="form-label" htmlFor="purchasedfrom">
                                    Allergy Restriction: <strong>{item.allergyrestriction}</strong>
                                </label>
                                <label className="form-label" htmlFor="purchasedfrom">
                                    Health Goal: <strong>{item.healthgoal}</strong>
                                </label>
                            </Space>
                        )
                    })}
                </Space>}
            {/* <Space direction="horizontal">
                <Table
                    columns={[
                        {
                            title: "Client Detail",
                            dataIndex: "key"
                        },
                        {
                            title: "",
                            dataIndex: "value"
                        },
                    ]}
                    dataSource={clientDetails}
                    pagination={false}>

                </Table>
                <EmptyView />
                <EmptyView />
                <EmptyView />
                <EmptyView />
                <EmptyView />
                <EmptyView />
            </Space> */}
            {/* <div class="col-md-6">
                <Space direction="horizontal">
                    <div class="text-muted mb-2">Client Name: <strong>{data.customerName}</strong></div>
                    <EmptyView />
                </Space>
                <Space direction="horizontal">
                    <div class="text-muted mb-2">Mobile: <strong>{data.mobile}</strong></div>
                    <EmptyView />
                </Space>
                <Space direction="horizontal">
                    <div class="text-muted mb-2">Gender: <strong>{data.gender}</strong></div>
                    <EmptyView />
                </Space>
                <Space direction="horizontal">
                    <div class="text-muted mb-2">Weight: <strong>{data.bodyweight}</strong></div>
                    <EmptyView />
                </Space>
                <Space direction="horizontal">
                    <div class="text-muted mb-2">Height: <strong>{data.height}</strong></div>
                    <EmptyView />
                </Space>
                <Space direction="horizontal">
                    <div class="text-muted mb-2">Profession: <strong>{data.lineofwork}</strong></div>
                    <EmptyView />
                </Space>
                <Space direction="horizontal">
                    <div class="text-muted mb-2">Health Concern: <strong>{data.healthconcern}</strong></div>
                    <EmptyView />
                </Space>
                <Space direction="horizontal">
                    <div class="text-muted mb-2">Allergy Restriction: <strong>{data.allergyrestriction}</strong></div>
                    <EmptyView />
                </Space>
                <Space direction="horizontal">
                    <div class="text-muted mb-2">Health Goal: <strong>{data.healthgoal}</strong></div>
                    <EmptyView />
                </Space>
            </div> */}
            <Space direction="horizontal" style={{ marginTop: 20, marginBottom: 20 }}>
                <strong>Monthwise BMI Details</strong>
                <EmptyView />
                <EmptyView />
                <EmptyView />
                <EmptyView />
                <EmptyView />
            </Space>
            <BarChart dates={selectedBMIDates} bmi={selectedBMI} />
            <Space direction="horizontal" style={{ marginTop: 20, marginBottom: 20 }}>
                <strong>Diet Plans</strong>
                <EmptyView />
                <EmptyView />
                <EmptyView />
                <EmptyView />
                <EmptyView />
            </Space>
            {!isMobile ?
                <Table
                    columns={[
                        {
                            title: "Name",
                            dataIndex: "clientName"
                        },
                        {
                            title: "Mobile",
                            dataIndex: "clientMobile"
                        },
                        {
                            title: "Weight",
                            dataIndex: "weight"
                        },
                        {
                            title: "Height",
                            dataIndex: "height"
                        },
                        {
                            title: "Line of Work",
                            dataIndex: "lineofwork"
                        },
                        {
                            title: "Health Concern",
                            dataIndex: "healthconcern"
                        },
                        {
                            title: "Goal",
                            dataIndex: "goal"
                        },
                        {
                            title: "Date",
                            dataIndex: "plandate"
                        },
                        {
                            title: "Number of Meals",
                            dataIndex: "meals"
                        },
                        {
                            title: "",
                            dataIndex: "plandetails",
                            render: (value, data, index) => <button className="btn btn-danger" onClick={() => {
                                //removeProduct(parseInt(data.id) - 1, index)
                                var tempData = JSON.parse(data.plandetails)
                                setPreviewMeals(tempData.meals)
                                setPreviewInstructions(tempData.instructions)
                                setIsPreviewModalVisible(true);
                            }}>Show Plan</button>
                        },
                    ]}
                    dataSource={dietPlans}>

                </Table> :
                <Space direction="vertical">
                    {dietPlans.map((item, index) => {
                        return (
                            <div key={item} style={{ borderRadius: "10px" }}>
                                <Card>
                                    <Space direction="vertical">
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Name: <strong>{item.clientName}</strong>
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Mobile: <strong>{item.clientMobile}</strong>
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Weight: <strong>{item.weight}</strong>
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Height: <strong>{item.weight}</strong>
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Line of Work: <strong>{item.lineofwork}</strong>
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Health Concern: <strong>{item.healthconcern}</strong>
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Goal: <strong>{item.goal}</strong>
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Date: <strong>{item.date}</strong>
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Meals: <strong>{item.meals}</strong>
                                        </label>
                                        <Space direction="horizontal">
                                            <button className="btn btn-danger" onClick={() => {
                                                //removeProduct(parseInt(data.id) - 1, index)
                                                //navigate('/adddietplan',  { state: { data: data }})
                                                var tempData = JSON.parse(data.plandetails)
                                                setPreviewMeals(tempData.meals)
                                                setPreviewInstructions(tempData.instructions)
                                                setIsPreviewModalVisible(true);
                                            }}>Show Plan</button>
                                            {/* <button className="btn btn-danger" onClick={() => {
                                                sendToAddDietPlan(item.clientID)
                                            }}>Add Plan</button> */}
                                        </Space>
                                    </Space>
                                </Card>
                            </div>
                        )
                    })}
                </Space>
            }
            <Space direction="horizontal" style={{ marginTop: 20, marginBottom: 20 }}>
                <strong>Workout Plans</strong>
                <EmptyView />
                <EmptyView />
                <EmptyView />
                <EmptyView />
                <EmptyView />
            </Space>
            {!isMobile ?
                <Table
                    columns={[
                        {
                            title: "Client Name",
                            dataIndex: "clientName"
                        },
                        {
                            title: "Workout Name",
                            dataIndex: "name"
                        },
                        {
                            title: "Type",
                            dataIndex: "type"
                        },
                        {
                            title: "Goal",
                            dataIndex: "goal"
                        },
                        {
                            title: "Phase",
                            dataIndex: "phase"
                        },
                        {
                            title: "",
                            dataIndex: "workouts",
                            render: (value, data, index) => <button className="btn btn-danger" onClick={() => {
                                //removeProduct(parseInt(data.id) - 1, index)
                                setIsPreviewWorkoutModalVisible(true);
                            }}>Show Plan</button>
                        },
                    ]}
                    dataSource={workouts}
                    pagination={false}>

                </Table> :
                <Space direction="vertical">
                    {workouts.map((item, index) => {
                        return (
                            <div key={item} style={{ borderRadius: "10px" }}>
                                <Card>
                                    <Space direction="vertical" style={{ width: 200 }}>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Name: <strong>{item.clientName}</strong>
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Workout Name: <strong>{item.name}</strong>
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Type: <strong>{item.type}</strong>
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Goal: <strong>{item.goal}</strong>
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Phase: <strong>{item.phase}</strong>
                                        </label>
                                        <button className="btn btn-danger" onClick={() => {
                                            //removeProduct(parseInt(data.id) - 1, index)
                                            setIsPreviewWorkoutModalVisible(true);
                                        }}>Show Plan</button>
                                    </Space>
                                </Card>
                            </div>
                        )
                    })}
                </Space>
            }
            <EmptyView />
            <EmptyView />
            <EmptyView />
            <div style={{ marginBottom: "200px" }}></div>
            <Modal
                visible={isPreviewModalVisible}
                okText="Print"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    setTimeout(() => {
                        handlePrint()
                    }, 500)
                }}
                onCancel={() => {
                    setIsPreviewModalVisible(false);
                }}>
                <div ref={componentRef} id="testId" class="container mt-10 mb-7">
                    <div class="row justify-content-center">
                        <div class="col-lg-15 col-xl-15">
                            <div class="card">
                                <div class="card-body p-5">
                                    <div class="row mb-3">
                                        <div class="col-md-6">
                                            <div class="text-muted mb-2">Customer Name</div>
                                            <strong>{data.customerName}</strong>
                                        </div>
                                        <div class="col-md-6 text-md-end">
                                            <div class="text-muted mb-2">Customer Mobile</div>
                                            <strong>{data.mobile}</strong>
                                        </div>
                                    </div>
                                    <Table
                                        columns={[
                                            {
                                                title: "Client Detail",
                                                dataIndex: "key"
                                            },
                                            {
                                                title: "",
                                                dataIndex: "value"
                                            },
                                        ]}
                                        dataSource={clientDetails}
                                        pagination={false}>

                                    </Table>
                                    <div class="row mb-3 mt-5">
                                        <div class="col-md-6">
                                            <strong>BMI Progress</strong>
                                        </div>
                                    </div>
                                    <BarChartSmall dates={selectedBMIDates} bmi={selectedBMI} />
                                    <div>
                                        {previewMeals.map((item, index) => {
                                            return (
                                                <div key={item}>
                                                    <Space direction="vertical">
                                                        <label className="form-label mt-4" htmlFor="purchasedfrom">
                                                            <strong>{item.mealname}</strong>
                                                        </label>
                                                        <label className="form-label" htmlFor="purchasedfrom">
                                                            <strong>Time: {item.time}</strong>
                                                        </label>
                                                        <Table
                                                            columns={[
                                                                {
                                                                    title: "Product Name",
                                                                    dataIndex: "itemname"
                                                                },
                                                                {
                                                                    title: "Type",
                                                                    dataIndex: "type"
                                                                },
                                                                {
                                                                    title: "Quantity",
                                                                    dataIndex: "quantity"
                                                                },
                                                            ]}
                                                            dataSource={item.products}
                                                            pagination={false}>

                                                        </Table>

                                                        <Space direction="vertical" style={{ marginTop: 30 }}>
                                                            <label className="form-label" htmlFor="purchasedfrom">
                                                                Protein: <strong>{getTotalProtiensCount(item.products)}</strong>, Carbs: <strong>{getTotalCarbsCount(item.products)}</strong>, Calories: <strong>{getTotalCaloriesCount(item.products)}</strong>, Fats: <strong>{getTotalFatsCount(item.products)}</strong>, Fibers: <strong>{getTotalFibersCount(item.products)}</strong>
                                                            </label>
                                                        </Space>
                                                        <Divider />
                                                    </Space>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {previewInstructions.map((item, index) => {
                                        return (
                                            <div>
                                                <Space direction="vertical">
                                                    <strong>{item.mealname}</strong>
                                                    <label className="form-label" htmlFor="purchasedfrom">
                                                        {item.instruction}
                                                    </label>
                                                    <Divider />
                                                </Space>
                                            </div>
                                        )
                                    })}
                                    <Divider />
                                    <div class="col-md-6">
                                        <strong>Receipies and Dish Contents</strong>
                                    </div>
                                    {previewMeals.map((item, index) => {
                                        return (
                                            <div>
                                                {item.receipies.map((item, index) => {
                                                    return (
                                                        <Space direction="vertical">
                                                            <Space direction="vertical">
                                                                <label className="form-label mt-5" htmlFor="purchasedfrom">
                                                                    Dish Name: <strong>{item.itemname}</strong>
                                                                </label>
                                                                <label className="form-label mt-1" htmlFor="purchasedfrom">
                                                                    <strong>Contents</strong>
                                                                </label>
                                                                <Table
                                                                    columns={[
                                                                        {
                                                                            title: "Product Name",
                                                                            dataIndex: "itemname"
                                                                        },
                                                                        {
                                                                            title: "Type",
                                                                            dataIndex: "type"
                                                                        },
                                                                        {
                                                                            title: "Quantity",
                                                                            dataIndex: "quantity"
                                                                        },
                                                                    ]}
                                                                    dataSource={item.subproductlist}
                                                                    pagination={false}>

                                                                </Table>
                                                                <label className="form-label mt-3" htmlFor="purchasedfrom">
                                                                    {item.receipe}
                                                                </label>
                                                            </Space>
                                                        </Space>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={isPreviewWorkoutModalVisible}
                okText="Print"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    setTimeout(() => {
                        handlePrint()
                    }, 500)
                }}
                onCancel={() => {
                    setIsPreviewWorkoutModalVisible(false);
                }}>
                <Space style={{ paddingTop: 30 }}>
                    <div ref={componentRef} id="testId" class="container_modal mt-10 mb-7">
                        {workouts.map((item, index) => {
                            return (
                                <div key={item}>
                                    <Space direction="vertical">
                                        <div class="row mb-3">
                                            <div class="col-md-6 mt-3">
                                                <div class="text-muted mb-2">Workout Name: <strong>{item.name}</strong></div>
                                            </div>
                                            <div class="col-md-6 text-md-end mt-3">
                                                <div class="text-muted mb-2">Workout Type: <strong>{item.type}</strong></div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-6">
                                                <div class="text-muted mb-2">Goal: <strong>{item.goal}</strong></div>
                                            </div>
                                            <div class="col-md-6 text-md-end mt-3">
                                                <div class="text-muted mb-2">Phase: <strong>{item.phase}</strong></div>
                                            </div>
                                        </div>
                                        {JSON.parse(item.workouts).map((item, index) => {
                                            return (
                                                <div key={item}>
                                                    <div class="row mb-3">
                                                        <div class="col-md-6 mt-3 ml-3">
                                                            <div class="text-muted mb-2">Workout Name: <strong>{item.Exercise}</strong></div>
                                                        </div>
                                                    </div>
                                                    {item.ExerciseType === "Cardio" ?
                                                        <Table
                                                            columns={[
                                                                {
                                                                    title: "Name",
                                                                    dataIndex: "Name"
                                                                },
                                                                {
                                                                    title: "Time",
                                                                    dataIndex: "Time"
                                                                },
                                                                {
                                                                    title: "Speed",
                                                                    dataIndex: "Speed"
                                                                },
                                                                {
                                                                    title: "Distance",
                                                                    dataIndex: "Distance"
                                                                },
                                                                {
                                                                    title: "Inclination",
                                                                    dataIndex: "Inclination"
                                                                },
                                                                {
                                                                    title: "Notes",
                                                                    dataIndex: "Notes"
                                                                },
                                                            ]}
                                                            dataSource={item.ExerciseList}
                                                            pagination={false}>

                                                        </Table> :
                                                        item.ExerciseType === "Weight Training" ?
                                                            <Table
                                                                columns={[
                                                                    {
                                                                        title: "Name",
                                                                        dataIndex: "Name"
                                                                    },
                                                                    {
                                                                        title: "Sets",
                                                                        dataIndex: "Sets"
                                                                    },
                                                                    {
                                                                        title: "Repo",
                                                                        dataIndex: "Repo"
                                                                    },
                                                                    {
                                                                        title: "Tempo",
                                                                        dataIndex: "Tempo"
                                                                    },
                                                                    {
                                                                        title: "RestInterval",
                                                                        dataIndex: "RestInterval"
                                                                    },
                                                                    {
                                                                        title: "Notes",
                                                                        dataIndex: "Notes"
                                                                    },
                                                                ]}
                                                                dataSource={item.ExerciseList}
                                                                pagination={false}>

                                                            </Table>
                                                            : <div></div>}
                                                </div>
                                            )
                                        })}

                                    </Space>
                                </div>
                            )
                        })}
                    </div>
                </Space>
            </Modal>
        </>
    )
}

export default ClientDetails;