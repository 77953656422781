import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { Card, Space, Statistic, Typography, Table, Divider, Modal, Input, DatePicker } from "antd";
import { reactLocalStorage } from 'reactjs-localstorage';
import { addProgress, getClients, getClientWiseProgress } from "../../API";

const ProgressList = () => {

    function EmptyView() {
        return (
            <Space>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </Space>
        )
    }

    function SmallEmptyView() {
        return (
            <Space>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </Space>
        )
    }

    const [trainerID, setTrainerID] = useState("");
    const [trainerName, setTrainerName] = useState("");
    const [progressList, setProgressList] = useState([])
    const [clients, setClients] = useState([])

    const [selectedClient, setSelectedClient] = useState("");
    const [selectedHeightWeight, setSelectedHeightWeight] = useState("");
    const [selectedBMIDates, setSelectedBMIDates] = useState([]);
    const [selectedBMI, setSelectedBMI] = useState([]);

    const [selectedClientID, setSelectedClientID] = useState("");
    const [selectedClientName, setSelectedClientName] = useState("");
    const [selectedClientMobile, setSelectedClientMobile] = useState("");
    const [selectedClientHeight, setSelectedClientHeight] = useState("");
    const [selectedClientWeight, setSelectedClientWeight] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [showAddProgress, setShowAddProgress] = useState(false)

    function BarChart({ Dates, List, ClientName, Height }) {

        const data = [
            Dates,
            List,
        ];

        const options = {
            chart: {
                title: ClientName,
                subtitle: Height,
            },
        };

        return (
            <Chart
                chartType="Bar"
                width="100%"
                height="400px"
                marginLeft="230px"
                data={data}
                options={options}
            />
        )
    }

    useEffect(() => {
        var tempID = reactLocalStorage.get("id");
        var tempName = reactLocalStorage.get("trainername");
        setTrainerID(tempID);
        setTrainerName(tempName);
        getClients(tempID)
            .then(res => {
                setClients(res.Clients)
            })
    }, []);

    const CalculateBMI = (weight, height) => {
        const bmi = parseFloat(weight) / parseFloat(height * height);
        console.log("Weight: " + weight + "Height: " + height);
        return bmi;
    }

    const AddProgressData = () => {
        if (selectedClientHeight === "") {
            alert("Please enter proper height")
        } else if (selectedClientWeight === "") {
            alert("Please enter proper weight")
        } else if (selectedDate === "") {
            alert("Please enter proper date")
        } else {
            addProgress(selectedClientID, selectedClientName, selectedClientMobile, selectedClientHeight, selectedClientWeight, selectedDate, trainerID, trainerName)
            .then(res => {
                alert("Progress Added Successfully.")
            })
        }
    }

    const getClientWiseProgressData = (id, name) => {
        getClientWiseProgress(trainerID, id)
        .then(res => {
            setProgressList(res.Progress)
            var tempBMIDates = ["Month"];
            var tempBMI = [new Date().getFullYear()]
            for (var i = 0; i < res.Progress.length; i++) {
                tempBMIDates.push(res.Progress[i].date)
                tempBMI.push(CalculateBMI(res.Progress[i].weight, parseFloat(res.Progress[i].height * 0.01)))
            }
            setSelectedBMIDates(tempBMIDates);
            setSelectedBMI(tempBMI);
            setSelectedClient(name)
        })
    }

    return (
        <>
            <Space direction="horizontal">
                <Space direction="vertical">
                    {progressList.length > 2 ?
                        <BarChart Dates={selectedBMIDates} List={selectedBMI} ClientName={selectedClient} Height={selectedHeightWeight} /> : <div></div>}
                    <Table
                        columns={[
                            {
                                title: "Name",
                                dataIndex: "name"
                            },
                            {
                                title: "Height",
                                dataIndex: "height"
                            },
                            {
                                title: "Weight",
                                dataIndex: "weight"
                            },
                            {
                                title: "",
                                dataIndex: "BMI",
                                render: (value, data, index) => <p>{CalculateBMI(data.weight, parseFloat(data.height * 0.01))}</p>
                            },
                            {
                                title: "",
                                dataIndex: "BMI",
                                render: (value, data, index) => <button className="btn btn-danger" onClick={() => {
                                    //removeProduct(parseInt(data.id) - 1, index)
                                    //navigate('/adddietplan', { state: { data: data } })
                                    // setSelectedClient(data.Name)
                                    // setSelectedBMIDates(data.BMIMeasurementDates)
                                    // setSelectedHeightWeight("Height: "+data.Height+" Weight: "+data.Weight);
                                    // setSelectedBMI(data.BMIList)
                                    setSelectedClientID(data.id)
                                    setSelectedClientName(data.name)
                                    setSelectedClientMobile(data.mobile)
                                    setSelectedHeightWeight("Height: "+data.height+", Weight: "+data.weight)
                                    getClientWiseProgressData(data.id, data.name)
                                }}>Show BMI</button>
                            },
                            {
                                title: "",
                                dataIndex: "BMI",
                                render: (value, data, index) => <button className="btn btn-danger" onClick={() => {
                                    //removeProduct(parseInt(data.id) - 1, index)
                                    //navigate('/adddietplan', { state: { data: data } })
                                    setSelectedClientID(data.id)
                                    setSelectedClientName(data.name)
                                    setSelectedClientMobile(data.mobile)
                                    setSelectedHeightWeight("Height: "+data.height+", Weight: "+data.weight)
                                    setShowAddProgress(true);
                                }}>Update Details</button>
                            },
                        ]}
                        dataSource={clients}>

                    </Table>
                </Space>
                <EmptyView />
                <EmptyView />
                <SmallEmptyView />
                <Modal
                    visible={showAddProgress}
                    okText="Save"
                    closable={false}
                    cancelText="Close"
                    onOk={() => {
                        //AddFoodToMeal(selectedIndex, selectedQuantity, selectedProduct)
                        //AddInstructions(mealname, instruction);
                        AddProgressData();
                        setShowAddProgress(false);
                    }}
                    onCancel={() => {
                        setShowAddProgress(false);
                    }}>
                    <div>
                        <div className="mb-3">
                            <Space direction="vertical">
                                <label className="form-label" htmlFor="stock">
                                    Set Height
                                </label>
                                <Input value={selectedClientHeight} className="form-control" id="stock" required onChange={(evt) => {
                                    //setEditedPrice(evt.target.value) 
                                    setSelectedClientHeight(evt.target.value)
                                }} />
                                <label className="form-label" htmlFor="stock">
                                    Set Weight
                                </label>
                                <Input value={selectedClientWeight} className="form-control" id="stock" required onChange={(evt) => {
                                    //setEditedPrice(evt.target.value) 
                                    setSelectedClientWeight(evt.target.value)
                                }} />
                                <label className="form-label" htmlFor="stock">
                                    Set Date
                                </label>
                                <DatePicker className="form-control" onChange={(dateString) => {
                                    // setStartDate(moment(date).format('YYYY-MM-DD'))
                                    //console.log(moment(dateString).format('YYYY-MM-DD'))
                                    //console.log(dateString)
                                    var tempStartDate = new Intl.DateTimeFormat('en-UK', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(dateString);
                                    console.log(tempStartDate.split('/').reverse().join('-'))
                                    setSelectedDate(tempStartDate.split('/').reverse().join('-'))
                                }} />
                            </Space>
                        </div>
                    </div>
                </Modal>
            </Space>

        </>
    )
}

export default ProgressList;