import { Card, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getProducts } from "../../API";
import { reactLocalStorage } from 'reactjs-localstorage';

const ProductsList = () => {

    const navigate = useNavigate();
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        var isMobile = reactLocalStorage.get("isMobile");
        if (isMobile === "true") {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
        getProducts()
            .then(res => {
                setProducts(res.Products)
            })
    }, [])

    function EmptyView() {
        return (
            <Space>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </Space>
        )
    }

    return (
        <Space size={20} direction="vertical" align="start">
            <Link to="/addproducts" className="btn btn-danger" type="submit">
                Add Product
            </Link>
            <button className="btn btn-danger" onClick={() => {
                //removeProduct(parseInt(data.id) - 1, index)
                navigate('/')
            }}>To Dashboard</button>
            <Space direction="horizontal">
                {!isMobile ?
                    <Table
                        columns={[
                            {
                                title: "Item Name",
                                dataIndex: "itemname"
                            },
                            {
                                title: "Type",
                                dataIndex: "type"
                            },
                            {
                                title: "Protein",
                                dataIndex: "protein"
                            },
                            {
                                title: "Carbs",
                                dataIndex: "carbs"
                            },
                            {
                                title: "Fats",
                                dataIndex: "fats"
                            },
                            {
                                title: "Fiber",
                                dataIndex: "fiber"
                            },
                            {
                                title: "Calories",
                                dataIndex: "calories"
                            },
                            {
                                title: "Unit",
                                dataIndex: "unit"
                            },
                            {
                                title: "",
                                dataIndex: "billdata",
                                render: (value, data) => <button className="btn btn-danger" onClick={() => {
                                    //navigate('/customerorders')

                                }}>Edit Item</button>
                            },
                        ]}
                        loading={loading}
                        dataSource={products}
                        pagination={{
                            pageSize: 5
                        }}>

                    </Table> :
                    <Space direction="vertical">
                        {products.map((item, index) => {
                            return (
                                <div key={item} style={{ borderRadius: "10px" }}>
                                    <Card>
                                        <Space direction="vertical" style={{ width: 200 }}>
                                            <label className="form-label" htmlFor="purchasedfrom">
                                                Name: <strong>{item.itemname}</strong>
                                            </label>
                                            <label className="form-label" htmlFor="purchasedfrom">
                                                Type: <strong>{item.type}</strong>
                                            </label>
                                            <label className="form-label" htmlFor="purchasedfrom">
                                                Protein: <strong>{item.protein}</strong>
                                            </label>
                                            <label className="form-label" htmlFor="purchasedfrom">
                                                Carbs: <strong>{item.carbs}</strong>
                                            </label>
                                            <label className="form-label" htmlFor="purchasedfrom">
                                                Fats: <strong>{item.fats}</strong>
                                            </label>
                                            <label className="form-label" htmlFor="purchasedfrom">
                                                Fiber: <strong>{item.fiber}</strong>
                                            </label>
                                            <label className="form-label" htmlFor="purchasedfrom">
                                                Calories: <strong>{item.calories}</strong>
                                            </label>
                                            <label className="form-label" htmlFor="purchasedfrom">
                                                Unit: <strong>{item.unit}</strong>
                                            </label>
                                            <button className="btn btn-danger" onClick={() => {
                                                //removeProduct(parseInt(data.id) - 1, index)
                                            }}>Edit Item</button>
                                        </Space>
                                    </Card>
                                </div>
                            )
                        })}
                    </Space>}
                <EmptyView />
                <EmptyView />
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </Space>
        </Space>
    )
}

export default ProductsList;