import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { AutoComplete, DatePicker, Divider, Input, Menu, Modal, Space, Table, TimePicker, Dropdown, Card } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { reactLocalStorage } from 'reactjs-localstorage';
import { addAppointment, addWorkoutPlans, getClientWiseWorkoutPlans, getExercises } from "../../API";

const AddWorkoutPlan = () => {

    const navigate = useNavigate();
    const { state } = useLocation();
    const { data } = state;
    const [trainerID, setTrainerID] = useState("");
    const [trainerName, setTrainerName] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
    const [workouts, setWorkouts] = useState([])
    const [previewWorkouts, setPreviewWorkouts] = useState([])
    const [isMobile, setIsMobile] = useState(false)
    // const [workouts, setWorkouts] = useState([
    //     {
    //         ClientName: "Sushant Mali",
    //         Name: "Chest/Back/Arms",
    //         Type: "3 Times/Week",
    //         Goal: "Build Lean Muscle",
    //         Phase: "Intermediate",
    //         Workouts: [
    //             {
    //                 Exercise: "Warm Up",
    //                 ExerciseList: [
    //                     {
    //                         Name: "",
    //                         Sets: "",
    //                         Repo: "",
    //                         Tempo: "5-10 min",
    //                         RestInterval: "",
    //                         Notes: "Choice of movement: Treadmill, Recumbent Cycle or Calesthenics"
    //                     }
    //                 ]
    //             },
    //             {
    //                 Exercise: "Chest",
    //                 ExerciseList: [
    //                     {
    //                         Name: "Incline Dumbbell Press",
    //                         Sets: "4",
    //                         Repo: "8-10",
    //                         Tempo: "2-1-3",
    //                         RestInterval: "1-2 min",
    //                         Notes: ""
    //                     },
    //                     {
    //                         Name: "Dips",
    //                         Sets: "3",
    //                         Repo: "8-10",
    //                         Tempo: "2-1-3",
    //                         RestInterval: "1-2 min",
    //                         Notes: "If you are strong enough for dips, try weighted dips"
    //                     }
    //                 ]
    //             },
    //             {
    //                 Exercise: "Back",
    //                 ExerciseList: [
    //                     {
    //                         Name: "Pull Ups",
    //                         Sets: "4",
    //                         Repo: "8-10",
    //                         Tempo: "2-1-3",
    //                         RestInterval: "1-2 min",
    //                         Notes: "Use assisted pull-up machine, bands or even a chair to assist with this one."
    //                     },
    //                     {
    //                         Name: "Front LAT Pulldown",
    //                         Sets: "3",
    //                         Repo: "8-10",
    //                         Tempo: "2-1-3",
    //                         RestInterval: "1-2 min",
    //                         Notes: ""
    //                     }
    //                 ]
    //             },
    //             {
    //                 Exercise: "Arms",
    //                 ExerciseList: [
    //                     {
    //                         Name: "Barbell Curl",
    //                         Sets: "3",
    //                         Repo: "8-10",
    //                         Tempo: "2-1-3",
    //                         RestInterval: "1-2 min",
    //                         Notes: ""
    //                     },
    //                     {
    //                         Name: "Seated Single Arm Triceps ext.",
    //                         Sets: "3",
    //                         Repo: "8-10",
    //                         Tempo: "2-1-3",
    //                         RestInterval: "1-2 min",
    //                         Notes: ""
    //                     }
    //                 ]
    //             },
    //             {
    //                 Exercise: "Lower-Abs",
    //                 ExerciseList: [
    //                     {
    //                         Name: "Leg Raise",
    //                         Sets: "2",
    //                         Repo: "10-12",
    //                         Tempo: "Slow Controlled",
    //                         RestInterval: "1-2 min",
    //                         Notes: ""
    //                     },
    //                     {
    //                         Name: "Knee/hip raise on parallel bar",
    //                         Sets: "2",
    //                         Repo: "10-12",
    //                         Tempo: "Slow Controlled",
    //                         RestInterval: "1-2 min",
    //                         Notes: ""
    //                     }
    //                 ]
    //             },
    //             {
    //                 Exercise: "Cardio",
    //                 ExerciseList: [
    //                     {
    //                         Name: "",
    //                         Sets: "",
    //                         Repo: "",
    //                         Tempo: "HIIT (15-20 Min)",
    //                         RestInterval: "",
    //                         Notes: ""
    //                     }
    //                 ]
    //             },
    //             {
    //                 Exercise: "Cool-Down",
    //                 ExerciseList: [
    //                     {
    //                         Name: "",
    //                         Sets: "",
    //                         Repo: "",
    //                         Tempo: "Foam Rolling",
    //                         RestInterval: "Static Stretches",
    //                         Notes: ""
    //                     }
    //                 ]
    //             },
    //         ]
    //     }
    // ]);
    const [exerciseName, setExerciseName] = useState("");
    const [exerciseType, setExerciseType] = useState("");
    const [showExerciseAddDialog, setShowExerciseAddDialog] = useState(false);
    const [newWorkouts, setNewWorkouts] = useState([]);
    const [showNewExerciseModal, setShowNewExerciseModal] = useState(false)
    const [selectedNewExerciseIndex, setSelectedNewExerciseIndex] = useState();
    const [selectedExercise, setSelectedExercise] = useState([]);
    const [inputValue, setInputValue] = useState('')
    const [selectedSets, setSelectedSets] = useState('');
    const [selectedRepo, setSelectedRepo] = useState('');
    const [selectedTempo, setSelectedTempo] = useState('');
    const [selectedRestInterval, setSelectedRestInterval] = useState('');
    const [selectedNotes, setSelectedNotes] = useState('');

    const [selectedWorkoutName, setSelectedWorkoutName] = useState('');
    const [selectedWorkoutType, setSelectedWorkoutType] = useState('');
    const [selectedWorkoutGoal, setSelectedWorkoutGoal] = useState('');
    const [selectedWorkoutPhase, setSelectedWorkoutPhase] = useState('');
    const [showWorkoutPreview, setShowWorkoutPreview] = useState(false);

    const [exercisesList, setExercisesList] = useState([]);

    useEffect(() => {
        var tempID = reactLocalStorage.get("id");
        var tempName = reactLocalStorage.get("trainername");
        var tempWorkouts = reactLocalStorage.get("workout");
        var isMobile = reactLocalStorage.get("isMobile");
        if (isMobile === "true") {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
        setTrainerID(tempID);
        setTrainerName(tempName);
        loadWorkoutData(tempID);
        if (tempWorkouts === "" || tempWorkouts === undefined || !tempWorkouts) {

        } else {
            setNewWorkouts(JSON.parse(tempWorkouts));
        }
    }, [])

    const loadWorkoutData = (trainerID) => {
        getClientWiseWorkoutPlans(trainerID, data.id)
            .then(res => {
                console.log(res.Workouts)
                setPreviewWorkouts(res.Workouts)
            })
    }

    const handleTypeClick = ({ key }) => {
        setExerciseType(key)
        //you can perform setState here
    }

    const category = (
        <Menu onClick={handleTypeClick}>
            <Menu.Item key="Cardio">Cardio</Menu.Item>
            <Menu.Item key="Weight Training">Weight Training</Menu.Item>
        </Menu>
    );

    const AddWorkout = (workout, exerciseType) => {
        let arr = [...newWorkouts]
        arr.push({
            Exercise: workout,
            ExerciseType: exerciseType,
            ExerciseList: []
        })
        setNewWorkouts(arr);
        reactLocalStorage.set("workout", JSON.stringify(arr));
    }

    const AddExerciseToWorkout = (exercise, index, sets, repo, tempo, restinterval, notes) => {
        let mainarr = [...newWorkouts];
        let arr = [...newWorkouts[index].ExerciseList]
        if (exerciseType === "Cardio") {
            arr.push({
                Name: exercise,
                Type: exerciseName,
                Time: sets,
                Speed: repo,
                Distance: tempo,
                Inclination: restinterval,
                Notes: notes,
                label: exercise,
                value: exercise
            },)
        } else if (exerciseType === "Weight Training") {
            arr.push({
                Name: exercise,
                Type: exerciseName,
                Sets: sets,
                Repo: repo,
                Tempo: tempo,
                RestInterval: restinterval,
                Notes: notes,
                label: exercise,
                value: exercise
            },)
        } else {

        }

        mainarr[index].ExerciseList = arr;
        setNewWorkouts(mainarr);
        reactLocalStorage.set("workout", JSON.stringify(mainarr));
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: data.name + "_WorkoutPlan"
    });

    function EmptyView() {
        return (
            <Space>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </Space>
        )
    }

    useEffect(() => {
        getExercises()
            .then(res => {
                setExercisesList(res.Exercises)
            })
    }, []);

    const AddWorkoutData = () => {
        var tempWorkout = JSON.stringify(newWorkouts)
        addWorkoutPlans(data.id, data.name, data.mobile, selectedWorkoutName, selectedWorkoutType, selectedWorkoutGoal, selectedWorkoutPhase, tempWorkout, trainerID, trainerName)
            .then(res => {
                alert("Workout Plan added successfully.")
                setTimeout(() => {
                    var today = new Date();
                    var dd = String(today.getDate()).padStart(2, '0');
                    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                    var yyyy = today.getFullYear();

                    today = dd + '/' + mm + '/' + yyyy;
                    var time = new Date(); // for now
                    time.getHours(); // => 9
                    time.getMinutes(); // =>  30
                    time.getSeconds(); // => 51
                    addAppointment(data.id, data.name, data.mobile, today, time.getHours() + ":" + time.getMinutes(), "Workout Plan", "1500", trainerID, trainerName)
                        .then(res => {
                            handlePrint()
                            loadWorkoutData(trainerID);
                            reactLocalStorage.set("workout", "");
                        })
                }, 500)
            })
    }

    const removeWorkout = (selectedWorkoutIndex, selectedIndex) => {
        let mainarr = [...newWorkouts];
        let arr = [...newWorkouts[selectedIndex].ExerciseList]
        arr.splice(selectedWorkoutIndex, 1)
        mainarr[selectedIndex].ExerciseList = arr;
        setNewWorkouts(mainarr);
        reactLocalStorage.set("workout", JSON.stringify(mainarr))
    }

    return (
        <>
            <h2>Add Workout Plan Here</h2>
            <Space direction="vertical">
                <button className="btn btn-danger" onClick={() => {
                    //removeProduct(parseInt(data.id) - 1, index)
                    navigate('/')
                }}>To Dashboard</button>
                <Space direction="horizontal" align="start">
                    <button className="btn btn-danger mt-3" onClick={() => {
                        setExerciseName("")
                        setShowExerciseAddDialog(true)
                    }}>Add Exercise</button>
                    <button className="btn btn-danger mt-3" onClick={() => setShowWorkoutPreview(true)}>Save Exercise Plan</button>
                    <button className="btn btn-danger mt-3" onClick={() => setShowWorkoutPreview(true)}>Print</button>
                </Space>
                {/* <Space direction="horizontal">
                <Table
                    columns={[
                        {
                            title: "Client Name",
                            dataIndex: "clientName"
                        },
                        {
                            title: "Workout Name",
                            dataIndex: "name"
                        },
                        {
                            title: "Type",
                            dataIndex: "type"
                        },
                        {
                            title: "Goal",
                            dataIndex: "goal"
                        },
                        {
                            title: "Phase",
                            dataIndex: "phase"
                        },
                        {
                            title: "",
                            dataIndex: "workouts",
                            render: (value, data, index) => <button className="btn btn-danger" onClick={() => {
                                //removeProduct(parseInt(data.id) - 1, index)
                                setIsPreviewModalVisible(true);
                            }}>Show Plan</button>
                        },
                    ]}
                    dataSource={previewWorkouts}
                    pagination={false}>

                </Table>
                <EmptyView />
                <EmptyView />
            </Space> */}
                <Space direction="vertical" style={{ marginBottom: '200px' }}>
                    {newWorkouts.map((item, index) => {
                        return (
                            <div key={item}>
                                <Space direction="vertical">
                                    <label className="form-label mt-5" htmlFor="purchasedfrom">
                                        {item.Exercise}
                                    </label>
                                    <button className="btn btn-danger mt-1 mb-1" onClick={() => {
                                        // setShowProductsModal(true)
                                        // setSelectedIndex(index)
                                        setShowNewExerciseModal(true)
                                        setSelectedNewExerciseIndex(index)
                                    }}>Add Exercise</button>
                                    {item.ExerciseType === "Cardio" ?
                                        <Space direction='vertical'>
                                            {!isMobile ?
                                                <Table
                                                    columns={[
                                                        {
                                                            title: "Name",
                                                            dataIndex: "Name"
                                                        },
                                                        {
                                                            title: "Time",
                                                            dataIndex: "Time"
                                                        },
                                                        {
                                                            title: "Speed",
                                                            dataIndex: "Speed"
                                                        },
                                                        {
                                                            title: "Distance",
                                                            dataIndex: "Distance"
                                                        },
                                                        {
                                                            title: "Inclination",
                                                            dataIndex: "Inclination"
                                                        },
                                                        {
                                                            title: "Notes",
                                                            dataIndex: "Notes"
                                                        },
                                                        {
                                                            title: "",
                                                            dataIndex: "workouts",
                                                            render: (value, data, selectedIndex) => <button className="btn btn-danger" onClick={() => {
                                                                //removeProduct(parseInt(data.id) - 1, index)
                                                                removeWorkout(index, selectedIndex)
                                                            }}>Remove Exercise</button>
                                                        },
                                                    ]}
                                                    dataSource={item.ExerciseList}
                                                    pagination={false}>

                                                </Table> :
                                                <Space direction="vertical">
                                                    {item.ExerciseList.map((item, selectedIndex) => {
                                                        return (
                                                            <div key={item} style={{ borderRadius: "10px" }}>
                                                                <Card>
                                                                    <Space direction="vertical" style={{ width: 200 }}>
                                                                        <label className="form-label" htmlFor="purchasedfrom">
                                                                            Name: <strong>{item.itemname}</strong>
                                                                        </label>
                                                                        <label className="form-label" htmlFor="purchasedfrom">
                                                                            Time: <strong>{item.Time}</strong>
                                                                        </label>
                                                                        <label className="form-label" htmlFor="purchasedfrom">
                                                                            Speed: <strong>{item.Speed}</strong>
                                                                        </label>
                                                                        <label className="form-label" htmlFor="purchasedfrom">
                                                                            Distance: <strong>{item.Distance}</strong>
                                                                        </label>
                                                                        <label className="form-label" htmlFor="purchasedfrom">
                                                                            Inclination: <strong>{item.Inclination}</strong>
                                                                        </label>
                                                                        <label className="form-label" htmlFor="purchasedfrom">
                                                                            Notes: <strong>{item.Notes}</strong>
                                                                        </label>
                                                                        <button className="btn btn-danger" onClick={() => {
                                                                            removeWorkout(index, selectedIndex)
                                                                        }}>Remove</button>
                                                                    </Space>
                                                                </Card>
                                                            </div>
                                                        )
                                                    })}
                                                </Space>}
                                        </Space>
                                        :
                                        item.ExerciseType === "Weight Training" ?

                                            <Space direction="vertical">
                                                {!isMobile ?
                                                    <Table
                                                        columns={[
                                                            {
                                                                title: "Name",
                                                                dataIndex: "Name"
                                                            },
                                                            {
                                                                title: "Sets",
                                                                dataIndex: "Sets"
                                                            },
                                                            {
                                                                title: "Repo",
                                                                dataIndex: "Repo"
                                                            },
                                                            {
                                                                title: "Tempo",
                                                                dataIndex: "Tempo"
                                                            },
                                                            {
                                                                title: "RestInterval",
                                                                dataIndex: "RestInterval"
                                                            },
                                                            {
                                                                title: "Notes",
                                                                dataIndex: "Notes"
                                                            },
                                                            {
                                                                title: "",
                                                                dataIndex: "workouts",
                                                                render: (value, data, selectedIndex) => <button className="btn btn-danger" onClick={() => {
                                                                    //removeProduct(parseInt(data.id) - 1, index)
                                                                    removeWorkout(index, selectedIndex)
                                                                }}>Remove Exercise</button>
                                                            },
                                                        ]}
                                                        dataSource={item.ExerciseList}
                                                        pagination={false}>

                                                    </Table> :
                                                    <Space direction="vertical">
                                                        {item.ExerciseList.map((item, selectedIndex) => {
                                                            return (
                                                                <div key={item} style={{ borderRadius: "10px" }}>
                                                                    <Card>
                                                                        <Space direction="vertical" style={{ width: 200 }}>
                                                                            <label className="form-label" htmlFor="purchasedfrom">
                                                                                Name: <strong>{item.itemname}</strong>
                                                                            </label>
                                                                            <label className="form-label" htmlFor="purchasedfrom">
                                                                                Sets: <strong>{item.Sets}</strong>
                                                                            </label>
                                                                            <label className="form-label" htmlFor="purchasedfrom">
                                                                                Repo: <strong>{item.Repo}</strong>
                                                                            </label>
                                                                            <label className="form-label" htmlFor="purchasedfrom">
                                                                                Tempo: <strong>{item.Tempo}</strong>
                                                                            </label>
                                                                            <label className="form-label" htmlFor="purchasedfrom">
                                                                                Rest Interval: <strong>{item.RestInterval}</strong>
                                                                            </label>
                                                                            <label className="form-label" htmlFor="purchasedfrom">
                                                                                Notes: <strong>{item.Notes}</strong>
                                                                            </label>
                                                                            <button className="btn btn-danger" onClick={() => {
                                                                                removeWorkout(index, selectedIndex)
                                                                            }}>Remove</button>
                                                                        </Space>
                                                                    </Card>
                                                                </div>
                                                            )
                                                        })}
                                                    </Space>}
                                            </Space>
                                            : <div></div>}
                                </Space>
                            </div>
                        )
                    })}

                    <EmptyView />
                    <EmptyView />
                    <EmptyView />
                </Space>
            </Space>
            <Modal
                visible={showNewExerciseModal}
                okText="Save"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    //AddFoodToMeal(selectedIndex, selectedQuantity, selectedProduct)
                    AddExerciseToWorkout(selectedExercise, selectedNewExerciseIndex, selectedSets, selectedRepo, selectedTempo, selectedRestInterval, selectedNotes)
                    setShowNewExerciseModal(false);
                }}
                onCancel={() => {
                    setShowNewExerciseModal(false);
                }}>
                <div>
                    <div className="mb-3">
                        <div>
                            <label className="form-label" htmlFor="purchasedfrom">
                                Search By Exercise Name
                            </label>
                            <AutoComplete
                                className="form-control"
                                //value={inputValueCustomer}
                                options={exercisesList}
                                autoFocus={true}
                                style={{ width: 200, height: 45, marginBottom: 10 }}
                                filterOption={(inputValue, option) =>
                                    option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                onSelect={(value, data) => {
                                    setSelectedExercise(value)
                                }}
                                onChange={() => {
                                    setInputValue("");
                                }}
                            />
                        </div>
                        <label className="form-label" htmlFor="stock">
                            Enter {exerciseType === "Cardio" ? "Time" : exerciseType === "Weight Training" ? "Sets" : ""}
                        </label>
                        <Input value={selectedSets} className="form-control" id="stock" required onChange={(evt) => {
                            //setEditedPrice(evt.target.value) 
                            setSelectedSets(evt.target.value)
                        }} />
                        <label className="form-label" htmlFor="stock">
                            Enter {exerciseType === "Cardio" ? "Speed" : exerciseType === "Weight Training" ? "Reps" : ""}
                        </label>
                        <Input value={selectedRepo} className="form-control" id="stock" required onChange={(evt) => {
                            //setEditedPrice(evt.target.value) 
                            setSelectedRepo(evt.target.value)
                        }} />
                        <label className="form-label" htmlFor="stock">
                            Enter {exerciseType === "Cardio" ? "Distance" : exerciseType === "Weight Training" ? "Tempo" : ""}
                        </label>
                        <Input value={selectedTempo} className="form-control" id="stock" required onChange={(evt) => {
                            //setEditedPrice(evt.target.value) 
                            setSelectedTempo(evt.target.value)
                        }} />
                        <label className="form-label" htmlFor="stock">
                            Enter {exerciseType === "Cardio" ? "Inclination" : exerciseType === "Weight Training" ? "Rest Interval" : ""}
                        </label>
                        <Input value={selectedRestInterval} className="form-control" id="stock" required onChange={(evt) => {
                            //setEditedPrice(evt.target.value) 
                            setSelectedRestInterval(evt.target.value)
                        }} />
                        <label className="form-label" htmlFor="stock">
                            Enter Notes
                        </label>
                        <Input value={selectedNotes} className="form-control" id="stock" required onChange={(evt) => {
                            //setEditedPrice(evt.target.value) 
                            setSelectedNotes(evt.target.value)
                        }} />
                    </div>
                </div>
            </Modal>
            <Modal
                visible={showWorkoutPreview}
                okText="Save"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    //AddFoodToMeal(selectedIndex, selectedQuantity, selectedProduct)
                    //AddExerciseToWorkout(selectedExercise, selectedNewExerciseIndex, selectedSets, selectedRepo, selectedTempo, selectedRestInterval, selectedNotes)
                    setShowWorkoutPreview(false);
                    setIsModalVisible(true)
                }}
                onCancel={() => {
                    setShowWorkoutPreview(false);
                }}>
                <div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="stock">
                            Enter Workout Name
                        </label>
                        <Input value={selectedWorkoutName} className="form-control" id="stock" required onChange={(evt) => {
                            //setEditedPrice(evt.target.value) 
                            setSelectedWorkoutName(evt.target.value)
                        }} />
                        <label className="form-label" htmlFor="stock">
                            Enter Type
                        </label>
                        <Input value={selectedWorkoutType} className="form-control" id="stock" required onChange={(evt) => {
                            //setEditedPrice(evt.target.value) 
                            setSelectedWorkoutType(evt.target.value)
                        }} />
                        <label className="form-label" htmlFor="stock">
                            Enter Goal
                        </label>
                        <Input value={selectedWorkoutGoal} className="form-control" id="stock" required onChange={(evt) => {
                            //setEditedPrice(evt.target.value) 
                            setSelectedWorkoutGoal(evt.target.value)
                        }} />
                        <label className="form-label" htmlFor="stock">
                            Enter Phase
                        </label>
                        <Input value={selectedWorkoutPhase} className="form-control" id="stock" required onChange={(evt) => {
                            //setEditedPrice(evt.target.value) 
                            setSelectedWorkoutPhase(evt.target.value)
                        }} />
                    </div>
                </div>
            </Modal>
            <Modal
                visible={showExerciseAddDialog}
                okText="Save"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    //AddFoodToMeal(selectedIndex, selectedQuantity, selectedProduct)
                    AddWorkout(exerciseName, exerciseType)
                    setShowExerciseAddDialog(false);
                }}
                onCancel={() => {
                    setShowExerciseAddDialog(false);
                }}>
                <div>
                    <div className="mb-3">
                        <Space direction="vertical">
                            <label className="form-label" htmlFor="stock">
                                Set Exercise Name
                            </label>
                            <Input value={exerciseName} className="form-control" id="stock" required onChange={(evt) => {
                                //setEditedPrice(evt.target.value) 
                                setExerciseName(evt.target.value)
                            }} />
                            <div className="mb-2">
                                <label className="form-label" htmlFor="stock">
                                    Set Exercise Type
                                </label>
                                <Dropdown overlay={category} trigger={["click"]}>
                                    <a
                                        style={{ height: 42 }}
                                        className="form-control"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        {exerciseType}
                                    </a>
                                </Dropdown>
                            </div>
                        </Space>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={isModalVisible}
                okText="Print"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    AddWorkoutData()
                }}
                onCancel={() => {
                    setIsModalVisible(false);
                }}>
                <Space style={{ paddingTop: 30 }}>
                    <div ref={componentRef} id="testId" class="container_modal mt-10 mb-7">
                        <div class="row mb-3">
                            <div class="col-md-6 mt-3">
                                <div class="text-muted mb-2">Workout Name: <strong>{selectedWorkoutName}</strong></div>
                            </div>
                            <div class="col-md-6 text-md-end mt-3">
                                <div class="text-muted mb-2">Workout Type: <strong>{selectedWorkoutType}</strong></div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <div class="text-muted mb-2">Goal: <strong>{selectedWorkoutGoal}</strong></div>
                            </div>
                            <div class="col-md-6 text-md-end mt-3">
                                <div class="text-muted mb-2">Phase: <strong>{selectedWorkoutPhase}</strong></div>
                            </div>
                        </div>
                        {newWorkouts.map((item, index) => {
                            return (
                                <div key={item}>
                                    <Space direction="vertical">
                                        <div key={item}>
                                            <div class="row mb-3">
                                                <div class="col-md-6 mt-3 ml-3">
                                                    <div class="text-muted mb-2">Workout Name: <strong>{item.Exercise}</strong></div>
                                                </div>
                                            </div>
                                            {item.ExerciseType === "Cardio" ?
                                                <Table
                                                    columns={[
                                                        {
                                                            title: "Name",
                                                            dataIndex: "Name"
                                                        },
                                                        {
                                                            title: "Time",
                                                            dataIndex: "Time"
                                                        },
                                                        {
                                                            title: "Speed",
                                                            dataIndex: "Speed"
                                                        },
                                                        {
                                                            title: "Distance",
                                                            dataIndex: "Distance"
                                                        },
                                                        {
                                                            title: "Inclination",
                                                            dataIndex: "Inclination"
                                                        },
                                                        {
                                                            title: "Notes",
                                                            dataIndex: "Notes"
                                                        },
                                                    ]}
                                                    dataSource={item.ExerciseList}
                                                    pagination={false}>

                                                </Table> :
                                                item.ExerciseType === "Weight Training" ?
                                                    <Table
                                                        columns={[
                                                            {
                                                                title: "Name",
                                                                dataIndex: "Name"
                                                            },
                                                            {
                                                                title: "Sets",
                                                                dataIndex: "Sets"
                                                            },
                                                            {
                                                                title: "Repo",
                                                                dataIndex: "Repo"
                                                            },
                                                            {
                                                                title: "Tempo",
                                                                dataIndex: "Tempo"
                                                            },
                                                            {
                                                                title: "RestInterval",
                                                                dataIndex: "RestInterval"
                                                            },
                                                            {
                                                                title: "Notes",
                                                                dataIndex: "Notes"
                                                            },
                                                        ]}
                                                        dataSource={item.ExerciseList}
                                                        pagination={false}>

                                                    </Table>
                                                    : <div></div>}
                                        </div>
                                    </Space>
                                </div>
                            )
                        })}
                    </div>
                </Space>
            </Modal>
            <Modal
                visible={isPreviewModalVisible}
                okText="Print"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    setTimeout(() => {
                        handlePrint()
                    }, 500)
                }}
                onCancel={() => {
                    setIsPreviewModalVisible(false);
                }}>
                <Space style={{ paddingTop: 30 }}>
                    <div ref={componentRef} id="testId" class="container_modal mt-10 mb-7">
                        {previewWorkouts.map((item, index) => {
                            return (
                                <div key={item}>
                                    <Space direction="vertical">
                                        <div class="row mb-3">
                                            <div class="col-md-6 mt-3">
                                                <div class="text-muted mb-2">Workout Name: <strong>{item.name}</strong></div>
                                            </div>
                                            <div class="col-md-6 text-md-end mt-3">
                                                <div class="text-muted mb-2">Workout Type: <strong>{item.type}</strong></div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-6">
                                                <div class="text-muted mb-2">Goal: <strong>{item.goal}</strong></div>
                                            </div>
                                            <div class="col-md-6 text-md-end mt-3">
                                                <div class="text-muted mb-2">Phase: <strong>{item.phase}</strong></div>
                                            </div>
                                        </div>
                                        {JSON.parse(item.workouts).map((item, index) => {
                                            return (
                                                <div key={item}>
                                                    <div class="row mb-3">
                                                        <div class="col-md-6 mt-3 ml-3">
                                                            <div class="text-muted mb-2">Workout Name: <strong>{item.Exercise}</strong></div>
                                                        </div>
                                                    </div>
                                                    {item.ExerciseType === "Cardio" ?
                                                        <Table
                                                            columns={[
                                                                {
                                                                    title: "Name",
                                                                    dataIndex: "Name"
                                                                },
                                                                {
                                                                    title: "Time",
                                                                    dataIndex: "Time"
                                                                },
                                                                {
                                                                    title: "Speed",
                                                                    dataIndex: "Speed"
                                                                },
                                                                {
                                                                    title: "Distance",
                                                                    dataIndex: "Distance"
                                                                },
                                                                {
                                                                    title: "Inclination",
                                                                    dataIndex: "Inclination"
                                                                },
                                                                {
                                                                    title: "Notes",
                                                                    dataIndex: "Notes"
                                                                },
                                                            ]}
                                                            dataSource={item.ExerciseList}
                                                            pagination={false}>

                                                        </Table> :
                                                        item.ExerciseType === "Weight Training" ?
                                                            <Table
                                                                columns={[
                                                                    {
                                                                        title: "Name",
                                                                        dataIndex: "Name"
                                                                    },
                                                                    {
                                                                        title: "Sets",
                                                                        dataIndex: "Sets"
                                                                    },
                                                                    {
                                                                        title: "Repo",
                                                                        dataIndex: "Repo"
                                                                    },
                                                                    {
                                                                        title: "Tempo",
                                                                        dataIndex: "Tempo"
                                                                    },
                                                                    {
                                                                        title: "RestInterval",
                                                                        dataIndex: "RestInterval"
                                                                    },
                                                                    {
                                                                        title: "Notes",
                                                                        dataIndex: "Notes"
                                                                    },
                                                                ]}
                                                                dataSource={item.ExerciseList}
                                                                pagination={false}>

                                                            </Table>
                                                            : <div></div>}
                                                </div>
                                            )
                                        })}

                                    </Space>
                                </div>
                            )
                        })}
                    </div>
                </Space>
            </Modal>
        </>
    )
}

export default AddWorkoutPlan;