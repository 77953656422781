import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { AutoComplete, Card, DatePicker, Divider, Input, Modal, Space, Table, TimePicker } from "antd";
import { reactLocalStorage } from 'reactjs-localstorage';
import { getClientByID, getWorkoutPlans } from "../../API";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../Components/useWindowDimensions";

const WorkoutList = () => {

    const navigate = useNavigate();
    const [trainerID, setTrainerID] = useState("");
    const [trainerName, setTrainerName] = useState("");
    const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
    const [workouts, setWorkouts] = useState([]);
    const componentRef = useRef();
    const [selectedClientName, setSelectedClientName] = useState("");
    const [isMobile, setIsMobile] = useState(false)

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: selectedClientName + "_WorkoutPlan"
    });

    function EmptyView() {
        return (
            <Space>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </Space>
        )
    }

    useEffect(() => {
        var tempID = reactLocalStorage.get("id");
        var tempName = reactLocalStorage.get("trainername");
        var isMobile = reactLocalStorage.get("isMobile");
        setTrainerID(tempID);
        setTrainerName(tempName);
        loadWorkoutData(tempID);
        if (isMobile === "true") {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [])

    const loadWorkoutData = (trainerID) => {
        getWorkoutPlans(trainerID)
            .then(res => {
                console.log(res.Workouts)
                setWorkouts(res.Workouts)
            })
    }

    const sendToAddDietPlan = (id) => {
        getClientByID(id)
            .then(res => {
                navigate('/addworkoutplan', { state: { data: res.Clients[0] } })
            })
    }

    return (
        <>
            <Space direction="vertical">
                <button className="btn btn-danger" onClick={() => {
                    //removeProduct(parseInt(data.id) - 1, index)
                    navigate('/')
                }}>To Dashboard</button>
                <Space direction="horizontal">
                    {!isMobile ?
                        <Table
                            columns={[
                                {
                                    title: "Client Name",
                                    dataIndex: "clientName"
                                },
                                {
                                    title: "Workout Name",
                                    dataIndex: "name"
                                },
                                {
                                    title: "Type",
                                    dataIndex: "type"
                                },
                                {
                                    title: "Goal",
                                    dataIndex: "goal"
                                },
                                {
                                    title: "Phase",
                                    dataIndex: "phase"
                                },
                                {
                                    title: "",
                                    dataIndex: "workouts",
                                    render: (value, data, index) => <button className="btn btn-danger" onClick={() => {
                                        //removeProduct(parseInt(data.id) - 1, index)
                                        setSelectedClientName(data.clientName)
                                        setIsPreviewModalVisible(true);
                                    }}>Show Plan</button>
                                },
                                {
                                    title: "",
                                    dataIndex: "workouts",
                                    render: (value, data, index) => <button className="btn btn-danger" onClick={() => {
                                        //removeProduct(parseInt(data.id) - 1, index)
                                        sendToAddDietPlan(data.clientID)
                                    }}>Add Plan</button>
                                },
                            ]}
                            dataSource={workouts}
                            pagination={false}>

                        </Table> :
                        <Space direction="vertical">
                            {workouts.map((item, index) => {
                                return (
                                    <div key={item} style={{ borderRadius: "10px" }}>
                                        <Card>
                                            <Space direction="vertical" style={{ width: 200 }}>
                                                <label className="form-label" htmlFor="purchasedfrom">
                                                    Name: <strong>{item.clientName}</strong>
                                                </label>
                                                <label className="form-label" htmlFor="purchasedfrom">
                                                    Workout Name: <strong>{item.name}</strong>
                                                </label>
                                                <label className="form-label" htmlFor="purchasedfrom">
                                                    Type: <strong>{item.type}</strong>
                                                </label>
                                                <label className="form-label" htmlFor="purchasedfrom">
                                                    Goal: <strong>{item.goal}</strong>
                                                </label>
                                                <label className="form-label" htmlFor="purchasedfrom">
                                                    Phase: <strong>{item.phase}</strong>
                                                </label>
                                                <button className="btn btn-danger" onClick={() => {
                                                    //removeProduct(parseInt(data.id) - 1, index)
                                                    setSelectedClientName(item.clientName)
                                                    setIsPreviewModalVisible(true);
                                                }}>Show Plan</button>
                                                <button className="btn btn-danger" onClick={() => {
                                                    //removeProduct(parseInt(data.id) - 1, index)
                                                    sendToAddDietPlan(item.clientID)
                                                }}>Add Plan</button>
                                            </Space>
                                        </Card>
                                    </div>
                                )
                            })}
                        </Space>}
                    <EmptyView />
                    <EmptyView />
                </Space>
            </Space>
            <Modal
                visible={isPreviewModalVisible}
                okText="Print"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    setTimeout(() => {
                        handlePrint()
                    }, 500)
                }}
                onCancel={() => {
                    setIsPreviewModalVisible(false);
                }}>
                <Space style={{ paddingTop: 30 }}>
                    <div ref={componentRef} id="testId" class="container_modal mt-10 mb-7">
                        {workouts.map((item, index) => {
                            return (
                                <div key={item}>
                                    <Space direction="vertical">
                                        <div class="row mb-3">
                                            <div class="col-md-6 mt-3">
                                                <div class="text-muted mb-2">Workout Name: <strong>{item.name}</strong></div>
                                            </div>
                                            <div class="col-md-6 text-md-end mt-3">
                                                <div class="text-muted mb-2">Workout Type: <strong>{item.type}</strong></div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-6">
                                                <div class="text-muted mb-2">Goal: <strong>{item.goal}</strong></div>
                                            </div>
                                            <div class="col-md-6 text-md-end mt-3">
                                                <div class="text-muted mb-2">Phase: <strong>{item.phase}</strong></div>
                                            </div>
                                        </div>
                                        {JSON.parse(item.workouts).map((item, index) => {
                                            return (
                                                <div key={item}>
                                                    <div class="row mb-3">
                                                        <div class="col-md-6 mt-3 ml-3">
                                                            <div class="text-muted mb-2">Workout Name: <strong>{item.Exercise}</strong></div>
                                                        </div>
                                                    </div>
                                                    {item.ExerciseType === "Cardio" ?
                                                        <Table
                                                            columns={[
                                                                {
                                                                    title: "Name",
                                                                    dataIndex: "Name"
                                                                },
                                                                {
                                                                    title: "Time",
                                                                    dataIndex: "Time"
                                                                },
                                                                {
                                                                    title: "Speed",
                                                                    dataIndex: "Speed"
                                                                },
                                                                {
                                                                    title: "Distance",
                                                                    dataIndex: "Distance"
                                                                },
                                                                {
                                                                    title: "Inclination",
                                                                    dataIndex: "Inclination"
                                                                },
                                                                {
                                                                    title: "Notes",
                                                                    dataIndex: "Notes"
                                                                },
                                                            ]}
                                                            dataSource={item.ExerciseList}
                                                            pagination={false}>

                                                        </Table> :
                                                        item.ExerciseType === "Weight Training" ?
                                                            <Table
                                                                columns={[
                                                                    {
                                                                        title: "Name",
                                                                        dataIndex: "Name"
                                                                    },
                                                                    {
                                                                        title: "Sets",
                                                                        dataIndex: "Sets"
                                                                    },
                                                                    {
                                                                        title: "Repo",
                                                                        dataIndex: "Repo"
                                                                    },
                                                                    {
                                                                        title: "Tempo",
                                                                        dataIndex: "Tempo"
                                                                    },
                                                                    {
                                                                        title: "RestInterval",
                                                                        dataIndex: "RestInterval"
                                                                    },
                                                                    {
                                                                        title: "Notes",
                                                                        dataIndex: "Notes"
                                                                    },
                                                                ]}
                                                                dataSource={item.ExerciseList}
                                                                pagination={false}>

                                                            </Table>
                                                            : <div></div>}
                                                </div>
                                            )
                                        })}

                                    </Space>
                                </div>
                            )
                        })}
                    </div>
                </Space>
            </Modal>
        </>
    )
}

export default WorkoutList;