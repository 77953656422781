import { Space, Table, Modal, Divider, Card } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { reactLocalStorage } from 'reactjs-localstorage';
import { getClientByID, getDietPlans } from "../../API";
import { useReactToPrint } from "react-to-print";
import useWindowDimensions from "../../Components/useWindowDimensions";

const DietPlans = () => {

    const navigate = useNavigate();
    const [dietPlans, setDietPlans] = useState([])
    const componentRef = useRef();

    const [trainerID, setTrainerID] = useState("");
    const [trainerName, setTrainerName] = useState("");
    const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
    const [previewMeals, setPreviewMeals] = useState([]);
    const [previewInstructions, setPreviewInstructions] = useState([]);
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        var tempID = reactLocalStorage.get("id");
        var tempName = reactLocalStorage.get("trainername");
        var isMobile = reactLocalStorage.get("isMobile");
        setTrainerID(tempID);
        setTrainerName(tempName);
        if (isMobile === "true") {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
        getDietPlans(tempID)
            .then(res => {
                setDietPlans(res.DietPlan)
            })
    }, [])

    const CalculateBMI = (weight, height) => {
        const bmi = parseFloat(weight) / parseFloat(height * height);
        console.log("Weight: " + weight + "Height: " + height);
        return bmi;
    }



    const getTotalProtiensCount = (products) => {
        var tempCount = 0.0;
        for (var i = 0; i < products.length; i++) {
            tempCount = tempCount + (parseFloat(products[i].protein) * parseFloat(products[i].quantity));
        }
        return parseFloat(tempCount);
    }

    const getTotalCaloriesCount = (products) => {
        var tempCount = 0.0;
        for (var i = 0; i < products.length; i++) {
            tempCount = tempCount + (parseFloat(products[i].calories) * parseFloat(products[i].quantity));
        }
        return parseFloat(tempCount);
    }

    const getTotalFibersCount = (products) => {
        var tempCount = 0.0;
        for (var i = 0; i < products.length; i++) {
            tempCount = tempCount + (parseFloat(products[i].fiber) * parseFloat(products[i].quantity));
        }
        return parseFloat(tempCount);
    }

    const getTotalCarbsCount = (products) => {
        var tempCount = 0.0;
        for (var i = 0; i < products.length; i++) {
            tempCount = tempCount + (parseFloat(products[i].carbs) * parseFloat(products[i].quantity));
        }
        return parseFloat(tempCount);
    }

    const getTotalFatsCount = (products) => {
        var tempCount = 0.0;
        for (var i = 0; i < products.length; i++) {
            tempCount = tempCount + (parseFloat(products[i].fats) * parseFloat(products[i].quantity));
        }
        return parseFloat(tempCount);
    }

    const [data, setData] = useState({})

    const [clientDetails, setClientDetails] = useState([])

    const getClientDataByID = (id) => {
        getClientByID(id)
            .then(res => {

                const tempData = [
                    {
                        key: "Gender",
                        value: res.Clients[0].gender
                    },
                    {
                        key: "age",
                        value: res.Clients[0].age
                    },
                    {
                        key: "Body Weight",
                        value: res.Clients[0].weight
                    },
                    {
                        key: "Height",
                        value: res.Clients[0].height
                    },
                    {
                        key: "Profession",
                        value: res.Clients[0].lineofwork
                    },
                    {
                        key: "Health Concern",
                        value: res.Clients[0].healthconcern
                    },
                    {
                        key: "Allergy Restriction",
                        value: res.Clients[0].allergyrestriction
                    },
                    {
                        key: "Health Goal",
                        value: res.Clients[0].healthgoal
                    },
                    {
                        key: "Sleeping Time",
                        value: res.Clients[0].sleepingtime
                    },
                    {
                        key: "BMI",
                        value: CalculateBMI(res.Clients[0].weight, parseFloat(res.Clients[0].height * 0.01))
                    }
                ]
                setData({
                    name: res.Clients[0].name,
                    mobile: res.Clients[0].mobile
                })
                setClientDetails(tempData);
                setIsPreviewModalVisible(true)
            })
    }

    const sendToAddDietPlan = (id) => {
        getClientByID(id)
            .then(res => {
                navigate('/adddietplan', { state: { data: res.Clients[0] } })
            })
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: data.name + "_DietPlan"
    });

    return (
        <>
            <Space direction="vertical">
                <button className="btn btn-danger" onClick={() => {
                    //removeProduct(parseInt(data.id) - 1, index)
                    navigate('/')
                }}>To Dashboard</button>
                {!isMobile ?
                    <Table
                        columns={[
                            {
                                title: "Name",
                                dataIndex: "clientName"
                            },
                            {
                                title: "Mobile",
                                dataIndex: "clientMobile"
                            },
                            {
                                title: "Weight",
                                dataIndex: "weight"
                            },
                            {
                                title: "Height",
                                dataIndex: "height"
                            },
                            {
                                title: "Line of Work",
                                dataIndex: "lineofwork"
                            },
                            {
                                title: "Health Concern",
                                dataIndex: "healthconcern"
                            },
                            {
                                title: "Goal",
                                dataIndex: "goal"
                            },
                            {
                                title: "Date",
                                dataIndex: "date"
                            },
                            {
                                title: "Number of Meals",
                                dataIndex: "meals"
                            },
                            {
                                title: "",
                                dataIndex: "plandetails",
                                render: (value, data, index) => <button className="btn btn-danger" onClick={() => {
                                    //removeProduct(parseInt(data.id) - 1, index)
                                    //navigate('/adddietplan',  { state: { data: data }})
                                    var tempData = JSON.parse(data.plandetails);
                                    //console.log(tempData)
                                    setPreviewMeals(tempData.meals)
                                    setPreviewInstructions(tempData.instructions)
                                    getClientDataByID(data.clientID)
                                    //console.log(tempData.meals);
                                }}>Show Plan</button>
                            },
                            {
                                title: "",
                                dataIndex: "plandetails",
                                render: (value, data, index) => <button className="btn btn-danger" onClick={() => {
                                    sendToAddDietPlan(data.clientID)
                                }}>Add Plan</button>
                            },
                        ]}
                        dataSource={dietPlans}>

                    </Table> :
                    <Space direction="vertical">
                        {dietPlans.map((item, index) => {
                            return (
                                <div key={item} style={{ borderRadius: "10px" }}>
                                    <Card>
                                        <Space direction="vertical">
                                            <label className="form-label" htmlFor="purchasedfrom">
                                                Name: <strong>{item.clientName}</strong>
                                            </label>
                                            <label className="form-label" htmlFor="purchasedfrom">
                                                Mobile: <strong>{item.clientMobile}</strong>
                                            </label>
                                            <label className="form-label" htmlFor="purchasedfrom">
                                                Weight: <strong>{item.weight}</strong>
                                            </label>
                                            <label className="form-label" htmlFor="purchasedfrom">
                                                Height: <strong>{item.weight}</strong>
                                            </label>
                                            <label className="form-label" htmlFor="purchasedfrom">
                                                Line of Work: <strong>{item.lineofwork}</strong>
                                            </label>
                                            <label className="form-label" htmlFor="purchasedfrom">
                                                Health Concern: <strong>{item.healthconcern}</strong>
                                            </label>
                                            <label className="form-label" htmlFor="purchasedfrom">
                                                Goal: <strong>{item.goal}</strong>
                                            </label>
                                            <label className="form-label" htmlFor="purchasedfrom">
                                                Date: <strong>{item.date}</strong>
                                            </label>
                                            <label className="form-label" htmlFor="purchasedfrom">
                                                Meals: <strong>{item.meals}</strong>
                                            </label>
                                            <Space direction="horizontal">
                                                <button className="btn btn-danger" onClick={() => {
                                                    //removeProduct(parseInt(data.id) - 1, index)
                                                    //navigate('/adddietplan',  { state: { data: data }})
                                                    var tempData = JSON.parse(item.plandetails);
                                                    setPreviewMeals(tempData.meals)
                                                    setPreviewInstructions(tempData.instructions)
                                                    getClientDataByID(item.clientID)
                                                }}>Show Plan</button>
                                                <button className="btn btn-danger" onClick={() => {
                                                    sendToAddDietPlan(item.clientID)
                                                }}>Add Plan</button>
                                            </Space>
                                        </Space>
                                    </Card>
                                </div>
                            )
                        })}
                    </Space>}
                <Modal
                    visible={isPreviewModalVisible}
                    okText="Print"
                    closable={false}
                    cancelText="Close"
                    onOk={() => {
                        setTimeout(() => {
                            handlePrint()
                        }, 500)
                    }}
                    onCancel={() => {
                        setIsPreviewModalVisible(false);
                    }}>
                    <div ref={componentRef} id="testId" class="container mt-10 mb-7">
                        <div class="row justify-content-center">
                            <div class="col-lg-15 col-xl-15">
                                <div class="card">
                                    <div class="card-body p-5">
                                        <div class="row mb-3">
                                            <div class="col-md-6">
                                                <div class="text-muted mb-2">Customer Name</div>
                                                <strong>{data.name}</strong>
                                            </div>
                                            <div class="col-md-6 text-md-end">
                                                <div class="text-muted mb-2">Customer Mobile</div>
                                                <strong>{data.mobile}</strong>
                                            </div>
                                        </div>
                                        <Table
                                            columns={[
                                                {
                                                    title: "Client Detail",
                                                    dataIndex: "key"
                                                },
                                                {
                                                    title: "",
                                                    dataIndex: "value"
                                                },
                                            ]}
                                            dataSource={clientDetails}
                                            pagination={false}>

                                        </Table>
                                        <div>
                                            {previewMeals.map((item, index) => {
                                                return (
                                                    <div key={item}>
                                                        <Space direction="vertical">
                                                            <label className="form-label mt-4" htmlFor="purchasedfrom">
                                                                <strong>{item.mealname}</strong>
                                                            </label>
                                                            <label className="form-label" htmlFor="purchasedfrom">
                                                                <strong>Time: {item.time}</strong>
                                                            </label>
                                                            <Table
                                                                columns={[
                                                                    {
                                                                        title: "Product Name",
                                                                        dataIndex: "itemname"
                                                                    },
                                                                    {
                                                                        title: "Type",
                                                                        dataIndex: "type"
                                                                    },
                                                                    {
                                                                        title: "Quantity",
                                                                        dataIndex: "quantity"
                                                                    },
                                                                ]}
                                                                dataSource={item.products}
                                                                pagination={false}>

                                                            </Table>

                                                            <Space direction="vertical" style={{ marginTop: 30 }}>
                                                                <label className="form-label" htmlFor="purchasedfrom">
                                                                    Protein: <strong>{getTotalProtiensCount(item.products)}</strong>, Carbs: <strong>{getTotalCarbsCount(item.products)}</strong>, Calories: <strong>{getTotalCaloriesCount(item.products)}</strong>, Fats: <strong>{getTotalFatsCount(item.products)}</strong>, Fibers: <strong>{getTotalFibersCount(item.products)}</strong>
                                                                </label>
                                                            </Space>
                                                            <Divider />
                                                        </Space>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        {previewInstructions.map((item, index) => {
                                            return (
                                                <div>
                                                    <Space direction="vertical">
                                                        <strong>{item.mealname}</strong>
                                                        <label className="form-label" htmlFor="purchasedfrom">
                                                            {item.instruction}
                                                        </label>
                                                        <Divider />
                                                    </Space>
                                                </div>
                                            )
                                        })}
                                        <Divider/>
                                        <div class="col-md-6">
                                            <strong>Receipies and Dish Contents</strong>
                                        </div>
                                        {previewMeals.map((item, index) => {
                                            return (
                                                <div>
                                                    {item.receipies.map((item, index) => {
                                                        return (
                                                            <Space direction="vertical">
                                                                <Space direction="vertical">
                                                                    <label className="form-label mt-5" htmlFor="purchasedfrom">
                                                                        Dish Name: <strong>{item.itemname}</strong>
                                                                    </label>
                                                                    <label className="form-label mt-1" htmlFor="purchasedfrom">
                                                                        <strong>Contents</strong>
                                                                    </label>
                                                                    <Table
                                                                        columns={[
                                                                            {
                                                                                title: "Product Name",
                                                                                dataIndex: "itemname"
                                                                            },
                                                                            {
                                                                                title: "Type",
                                                                                dataIndex: "type"
                                                                            },
                                                                            {
                                                                                title: "Quantity",
                                                                                dataIndex: "quantity"
                                                                            },
                                                                        ]}
                                                                        dataSource={item.subproductlist}
                                                                        pagination={false}>

                                                                    </Table>
                                                                    <label className="form-label mt-3" htmlFor="purchasedfrom">
                                                                        {item.receipe}
                                                                    </label>
                                                                </Space>
                                                            </Space>
                                                        )
                                                    })}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </Space>
        </>
    )
}

export default DietPlans;