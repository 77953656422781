import { AutoComplete, Card, DatePicker, Divider, Input, Modal, Space, Table, TimePicker } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { addAppointment, addDietPlan, getClientWiseDietPlan, getProducts } from "../../API";
import { reactLocalStorage } from 'reactjs-localstorage';

const AddDietPlan = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { data } = state;
    const format = 'HH:mm';
    const componentRef = useRef();
    const [meals, setMeals] = useState([]);
    const [previewMeals, setPreviewMeals] = useState([]);
    const [receipies, setReceipies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [showProductsModal, setShowProductsModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [selectedIndex, setSelectedIndex] = useState(10000);
    const [selectedQuantity, setSelectedQuantity] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
    const [showTimeModal, setShowTimeModal] = useState(false);
    const [showInstructionModal, setShowInstructionModal] = useState(false);
    const [mealname, setMealName] = useState('');
    const [instructions, setInstructions] = useState([]);
    const [previewInstructions, setPreviewInstructions] = useState([]);
    const [instruction, setInstruction] = useState('');
    const [time, setTime] = useState('12:08');
    const [products, setProducts] = useState([]);
    const [displayDietPlans, setDisplatDietPlans] = useState([]);
    const [dietPlans, setDietPlans] = useState([]);
    const [isMobile, setIsMobile] = useState(false)
    // const [dietPlans, setDietPlans] = useState([
    //     {
    //         clientName: data.customerName,
    //         clientMobile: data.mobile,
    //         weight: data.weight,
    //         height: data.height,
    //         lineofwork: data.lineofwork,
    //         healthconcern: data.healthconcern,
    //         sleepingtime: data.sleepingtime,
    //         goal: data.goal,
    //         plandate: "15/03/2024",
    //         meals: 3,
    //         plandetails: {
    //             meals: [
    //                 {
    //                     time: "10:00 AM",
    //                     mealname: "POST-Workout",
    //                     products: [
    //                         {
    //                             id: 2,
    //                             itemname: "Egg Full",
    //                             type: "Non-Veg",
    //                             protein: "6.3",
    //                             carbs: "0.55",
    //                             fats: "5.3",
    //                             fiber: "0",
    //                             calories: "77.5",
    //                             value: "Egg Full",
    //                             label: "Egg Full",
    //                             quantity: 2
    //                         },
    //                         {
    //                             id: 3,
    //                             itemname: "Whey Protein whole scoop",
    //                             type: "Veg",
    //                             protein: "22.3",
    //                             carbs: "0.55",
    //                             fats: "5.3",
    //                             fiber: "0",
    //                             calories: "100.5",
    //                             value: "Whey Protein whole scoop",
    //                             label: "Whey Protein whole scoop",
    //                             quantity: 1
    //                         }
    //                     ]
    //                 },

    //             ],
    //             instructions: [
    //                 {
    //                     instruction: "Your nutrition plan includes one cheat every 10 days. However, it must be had in portion control. On the day of your cheat meal, its recommended to increase workout intensity to burn extra calories. Additionally, aim for lighter home cooked meals throughout the rest of the day.",
    //                     mealname: "Cheat Meal"
    //                 }
    //             ]
    //         }
    //     }
    // ])
    const [trainerID, setTrainerID] = useState("");
    const [trainerName, setTrainerName] = useState("");

    const CalculateBMI = (weight, height) => {
        const bmi = parseFloat(weight) / parseFloat(height * height);
        return bmi;
    }

    const [clientDetails, setClientDetails] = useState([
        {
            key: "Gender",
            value: data.gender
        },
        {
            key: "age",
            value: data.age
        },
        {
            key: "Body Weight",
            value: data.weight
        },
        {
            key: "Height",
            value: data.height
        },
        {
            key: "Profession",
            value: data.lineofwork
        },
        {
            key: "Health Concern",
            value: data.healthconcern
        },
        {
            key: "Allergy Restriction",
            value: data.allergyrestriction
        },
        {
            key: "Health Goal",
            value: data.healthgoal
        },
        {
            key: "Sleeping Time",
            value: data.sleepingtime
        },
        {
            key: "BMI",
            value: CalculateBMI(data.weight, parseFloat(data.height * 0.01))
        }
    ])



    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: data.name + "_DietPlan"
    });

    const AddMeal = (time, mealname, type) => {
        let arr = [...meals]
        arr.push({
            products: [],
            receipies: [],
            time: time,
            mealname: mealname
        })
        setMeals(arr);
        reactLocalStorage.set("meals", JSON.stringify(arr))
    }

    const AddInstructions = (mealname, instruction) => {
        let arr = [...instructions]
        arr.push({
            instruction: instruction,
            mealname: mealname
        })
        setInstructions(arr);
        reactLocalStorage.set("instructions", JSON.stringify(arr))
    }

    const AddFoodToMeal = (index, quantity, product) => {
        let mainarr = [...meals];
        let arr = [...meals[index].products]
        let arrRecep = [...meals[index].receipies]
        let tempsubproductlist = product.subproductlist === "" ? [] : JSON.parse(product.subproductlist)
        if (product.subproductlist === "") {

        } else {
            arrRecep.push({
                itemname: product.itemname,
                receipe: product.receipe,
                subproductlist: tempsubproductlist
            })
        }

        arr.push({
            id: product.id,
            itemname: product.itemname,
            type: product.type,
            protein: product.protein,
            carbs: product.carbs,
            fats: product.fats,
            fiber: product.fiber,
            calories: product.calories,
            subproductlist: product.subproductlist === "" ? "" : [],
            receipe: product.receipe,
            value: product.value,
            label: product.label,
            quantity: quantity
        })
        mainarr[index].products = arr;
        if (product.subproductlist === "") {

        } else {
            mainarr[index].receipies = arrRecep;
        }
        setMeals(mainarr);
        reactLocalStorage.set("meals", JSON.stringify(mainarr))
    }

    useEffect(() => {
        var isMobile = reactLocalStorage.get("isMobile");
        if (isMobile === "true") {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
        getProducts()
            .then(res => {
                setProducts(res.Products)
            })
    }, [])

    useEffect(() => { // this hook will get called every time myArr has changed
        // perform some action every time myArr is updated
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 100)
    }, [meals])

    function EmptyView() {
        return (
            <Space>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </Space>
        )
    }

    const getTotalProtiensCount = (products) => {
        var tempCount = 0.0;
        for (var i = 0; i < products.length; i++) {
            tempCount = tempCount + (parseFloat(products[i].protein) * parseFloat(products[i].quantity));
        }
        return parseFloat(tempCount);
    }

    const getTotalFibersCount = (products) => {
        var tempCount = 0.0;
        for (var i = 0; i < products.length; i++) {
            tempCount = tempCount + (parseFloat(products[i].fiber) * parseFloat(products[i].quantity));
        }
        return parseFloat(tempCount);
    }

    const getTotalCaloriesCount = (products) => {
        var tempCount = 0.0;
        for (var i = 0; i < products.length; i++) {
            tempCount = tempCount + (parseFloat(products[i].calories) * parseFloat(products[i].quantity));
        }
        return parseFloat(tempCount);
    }

    const getTotalCarbsCount = (products) => {
        var tempCount = 0.0;
        for (var i = 0; i < products.length; i++) {
            tempCount = tempCount + (parseFloat(products[i].carbs) * parseFloat(products[i].quantity));
        }
        return parseFloat(tempCount);
    }

    const getTotalFatsCount = (products) => {
        var tempCount = 0.0;
        for (var i = 0; i < products.length; i++) {
            tempCount = tempCount + (parseFloat(products[i].fats) * parseFloat(products[i].quantity));
        }
        return parseFloat(tempCount);
    }

    const removeProduct = (selectedProductIndex, selectedIndex) => {
        let mainarr = [...meals];
        let arr = [...meals[selectedIndex].products]
        arr.splice(selectedProductIndex, 1)
        mainarr[selectedIndex].products = arr;
        setMeals(mainarr);
        reactLocalStorage.set("meals", JSON.stringify(mainarr))
    }

    const AddDietPlanData = () => {
        if (meals.length === 0) {
            alert("Please add at least 1 meal.")
        } else {
            const tempDietObject = {
                meals: meals,
                instructions: instructions
            }
            addDietPlan(data.id, data.name, data.mobile, data.weight, data.height, data.lineofwork, data.healthconcern, data.sleepingtime, data.goal, getDate(), parseInt(meals.length), JSON.stringify(tempDietObject), trainerID, trainerName)
                .then(res => {
                    alert("Diet Plan Added Successfully.")
                    setTimeout(() => {
                        var today = new Date();
                        var dd = String(today.getDate()).padStart(2, '0');
                        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                        var yyyy = today.getFullYear();

                        today = dd + '/' + mm + '/' + yyyy;
                        var time = new Date(); // for now
                        time.getHours(); // => 9
                        time.getMinutes(); // =>  30
                        time.getSeconds(); // => 51
                        addAppointment(data.id, data.name, data.mobile, today, time.getHours() + ":" + time.getMinutes(), "Diet Plan", "1500", trainerID, trainerName)
                            .then(res => {
                                handlePrint()
                                loadDietPlans(trainerID, data.id);
                                setMeals([])
                                setInstruction([])
                                reactLocalStorage.set("meals", "")
                            })
                    }, 500)
                })
        }
    }

    const loadDietPlans = (trainer_id, clientId) => {
        getClientWiseDietPlan(trainer_id, clientId)
            .then(res => {
                setDisplatDietPlans(res.DietPlan)
            })
    }

    function getDate() {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        return date + "/" + month + "/" + year;
    }

    useEffect(() => {
        var tempID = reactLocalStorage.get("id");
        var tempName = reactLocalStorage.get("trainername");
        var tempMeals = reactLocalStorage.get("meals")
        var tempInstructions = reactLocalStorage.get("instructions")
        setTrainerID(tempID);
        setTrainerName(tempName);
        loadDietPlans(tempID, data.id)
        //console.log(tempMeals);
        if (tempMeals === "" || tempMeals === undefined || !tempMeals) {

        } else {
            var tempMealsArray = JSON.parse(tempMeals)
            setMeals(tempMealsArray);
        }
        if (tempInstructions === "" || tempInstructions === undefined || !tempInstructions) {

        } else {
            var tempInstructionArray = JSON.parse(tempInstructions)
            setInstructions(tempInstructionArray);
        }
        // try {


        // } catch (error) {

        // }
    }, [])

    return (
        <>
            {loading ? <p>Loading...</p> :
                <Space direction="vertical">
                    <Modal
                        visible={showTimeModal}
                        okText="Save"
                        closable={false}
                        cancelText="Close"
                        onOk={() => {
                            //AddFoodToMeal(selectedIndex, selectedQuantity, selectedProduct)
                            AddMeal(time, mealname);
                            setShowTimeModal(false);
                        }}
                        onCancel={() => {
                            setShowTimeModal(false);
                        }}>
                        <div>
                            <div className="mb-3">
                                <Space direction="vertical">
                                    <label className="form-label" htmlFor="stock">
                                        Set Meal Name
                                    </label>
                                    <Input value={mealname} className="form-control" id="stock" required onChange={(evt) => {
                                        //setEditedPrice(evt.target.value) 
                                        setMealName(evt.target.value)
                                    }} />
                                    <label className="form-label" htmlFor="stock">
                                        Select Time
                                    </label>
                                    <TimePicker format={format} use12Hours
                                        onChange={(value, dateString) => {
                                            // console.log(dateString)
                                            // console.log(value)
                                            setTime(dateString);
                                        }} />
                                </Space>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        visible={showInstructionModal}
                        okText="Save"
                        closable={false}
                        cancelText="Close"
                        onOk={() => {
                            //AddFoodToMeal(selectedIndex, selectedQuantity, selectedProduct)
                            AddInstructions(mealname, instruction);
                            setShowInstructionModal(false);
                        }}
                        onCancel={() => {
                            setShowInstructionModal(false);
                        }}>
                        <div>
                            <div className="mb-3">
                                <Space direction="vertical">
                                    <label className="form-label" htmlFor="stock">
                                        Set Instruction Name
                                    </label>
                                    <Input value={mealname} className="form-control" id="stock" required onChange={(evt) => {
                                        //setEditedPrice(evt.target.value) 
                                        setMealName(evt.target.value)
                                    }} />
                                    <label className="form-label" htmlFor="stock">
                                        Set Instructions
                                    </label>
                                    <Input value={instruction} className="form-control" id="stock" required onChange={(evt) => {
                                        //setEditedPrice(evt.target.value) 
                                        setInstruction(evt.target.value)
                                    }} />
                                </Space>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        visible={showProductsModal}
                        okText="Save"
                        closable={false}
                        cancelText="Close"
                        onOk={() => {
                            AddFoodToMeal(selectedIndex, selectedQuantity, selectedProduct)
                            setShowProductsModal(false);
                        }}
                        onCancel={() => {
                            setShowProductsModal(false);
                        }}>
                        <div>
                            <div className="mb-3">
                                <div>
                                    <label className="form-label" htmlFor="purchasedfrom">
                                        Search By Food Name
                                    </label>
                                    <AutoComplete
                                        className="form-control"
                                        //value={inputValueCustomer}
                                        options={products}
                                        autoFocus={true}
                                        style={{ width: 200, height: 45, marginBottom: 10 }}
                                        filterOption={(inputValue, option) =>
                                            option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onSelect={(value, data) => {
                                            setSelectedProduct(data)
                                        }}
                                        onChange={() => {
                                            setInputValue("");
                                        }}
                                    />
                                </div>
                                <label className="form-label" htmlFor="stock">
                                    Enter Amount
                                </label>
                                <Input value={selectedQuantity} className="form-control" type="number" id="stock" required onChange={(evt) => {
                                    //setEditedPrice(evt.target.value) 
                                    setSelectedQuantity(evt.target.value)
                                }} />
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        visible={isModalVisible}
                        okText="Print"
                        closable={false}
                        cancelText="Close"
                        onOk={() => {
                            AddDietPlanData()
                        }}
                        onCancel={() => {
                            setIsModalVisible(false);
                        }}>
                        <div ref={componentRef} id="testId" class="container mt-10 mb-7">
                            <div class="row justify-content-center">
                                <div class="col-lg-15 col-xl-15">
                                    <div class="card">
                                        <div class="card-body p-5">
                                            <div class="row mb-3">
                                                <div class="col-md-6">
                                                    <div class="text-muted mb-2">Customer Name</div>
                                                    <strong>{data.name}</strong>
                                                </div>
                                                <div class="col-md-6 text-md-end">
                                                    <div class="text-muted mb-2">Customer Mobile</div>
                                                    <strong>{data.mobile}</strong>
                                                </div>
                                            </div>
                                            <Table
                                                columns={[
                                                    {
                                                        title: "Client Detail",
                                                        dataIndex: "key"
                                                    },
                                                    {
                                                        title: "",
                                                        dataIndex: "value"
                                                    },
                                                ]}
                                                dataSource={clientDetails}
                                                pagination={false}>

                                            </Table>
                                            <div>
                                                {meals.map((item, index) => {
                                                    return (
                                                        <div key={item}>
                                                            <Space direction="vertical">
                                                                <label className="form-label mt-4" htmlFor="purchasedfrom">
                                                                    <strong>{item.mealname}</strong>
                                                                </label>
                                                                <label className="form-label" htmlFor="purchasedfrom">
                                                                    <strong>Time: {item.time}</strong>
                                                                </label>
                                                                <Table
                                                                    columns={[
                                                                        {
                                                                            title: "Product Name",
                                                                            dataIndex: "itemname"
                                                                        },
                                                                        {
                                                                            title: "Type",
                                                                            dataIndex: "type"
                                                                        },
                                                                        {
                                                                            title: "Quantity",
                                                                            dataIndex: "quantity"
                                                                        },
                                                                    ]}
                                                                    dataSource={item.products}
                                                                    pagination={false}>

                                                                </Table>

                                                                <Space direction="vertical" style={{ marginTop: 30 }}>
                                                                    <label className="form-label" htmlFor="purchasedfrom">
                                                                        Protein: <strong>{getTotalProtiensCount(item.products)}</strong>, Carbs: <strong>{getTotalCarbsCount(item.products)}</strong>, Calories: <strong>{getTotalCaloriesCount(item.products)}</strong>, Fats: <strong>{getTotalFatsCount(item.products)}</strong>, Fibers: <strong>{getTotalFibersCount(item.products)}</strong>
                                                                    </label>
                                                                </Space>
                                                                <Divider />
                                                            </Space>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            {instructions.map((item, index) => {
                                                return (
                                                    <div>
                                                        <Space direction="vertical">
                                                            <strong>{item.mealname}</strong>
                                                            <label className="form-label" htmlFor="purchasedfrom">
                                                                {item.instruction}
                                                            </label>
                                                            <Divider />
                                                        </Space>
                                                    </div>
                                                )
                                            })}
                                            <div class="row mb-3">
                                                <div class="col-md-6">
                                                    <strong>Receipies and Dish Contents</strong>
                                                </div>
                                                {meals.map((item, index) => {
                                                    return (
                                                        <div>
                                                            {item.receipies.map((item, index) => {
                                                                return (
                                                                    <Space direction="vertical">
                                                                        <Space direction="vertical">
                                                                            <label className="form-label mt-5" htmlFor="purchasedfrom">
                                                                                Dish Name: <strong>{item.itemname}</strong>
                                                                            </label>
                                                                            <label className="form-label mt-1" htmlFor="purchasedfrom">
                                                                                <strong>Contents</strong>
                                                                            </label>
                                                                            <Table
                                                                                columns={[
                                                                                    {
                                                                                        title: "Product Name",
                                                                                        dataIndex: "itemname"
                                                                                    },
                                                                                    {
                                                                                        title: "Type",
                                                                                        dataIndex: "type"
                                                                                    },
                                                                                    {
                                                                                        title: "Quantity",
                                                                                        dataIndex: "quantity"
                                                                                    },
                                                                                ]}
                                                                                dataSource={item.subproductlist}
                                                                                pagination={false}>

                                                                            </Table>
                                                                            <label className="form-label mt-3" htmlFor="purchasedfrom">
                                                                                {item.receipe}
                                                                            </label>
                                                                        </Space>
                                                                    </Space>
                                                                )
                                                            })}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        visible={isPreviewModalVisible}
                        okText="Print"
                        closable={false}
                        cancelText="Close"
                        onOk={() => {
                            setTimeout(() => {
                                handlePrint()
                            }, 500)
                        }}
                        onCancel={() => {
                            setIsPreviewModalVisible(false);
                        }}>
                        <div ref={componentRef} id="testId" class="container mt-10 mb-7">
                            <div class="row justify-content-center">
                                <div class="col-lg-15 col-xl-15">
                                    <div class="card">
                                        <div class="card-body p-5">
                                            <div class="row mb-3">
                                                <div class="col-md-6">
                                                    <div class="text-muted mb-2">Customer Name</div>
                                                    <strong>{data.name}</strong>
                                                </div>
                                                <div class="col-md-6 text-md-end">
                                                    <div class="text-muted mb-2">Customer Mobile</div>
                                                    <strong>{data.mobile}</strong>
                                                </div>
                                            </div>
                                            <Table
                                                columns={[
                                                    {
                                                        title: "Client Detail",
                                                        dataIndex: "key"
                                                    },
                                                    {
                                                        title: "",
                                                        dataIndex: "value"
                                                    },
                                                ]}
                                                dataSource={clientDetails}
                                                pagination={false}>

                                            </Table>
                                            <div>
                                                {previewMeals.map((item, index) => {
                                                    return (
                                                        <div key={item}>
                                                            <Space direction="vertical">
                                                                <label className="form-label mt-4" htmlFor="purchasedfrom">
                                                                    <strong>{item.mealname}</strong>
                                                                </label>
                                                                <label className="form-label" htmlFor="purchasedfrom">
                                                                    <strong>Time: {item.time}</strong>
                                                                </label>
                                                                <Table
                                                                    columns={[
                                                                        {
                                                                            title: "Product Name",
                                                                            dataIndex: "itemname"
                                                                        },
                                                                        {
                                                                            title: "Type",
                                                                            dataIndex: "type"
                                                                        },
                                                                        {
                                                                            title: "Quantity",
                                                                            dataIndex: "quantity"
                                                                        },
                                                                    ]}
                                                                    dataSource={item.products}
                                                                    pagination={false}>

                                                                </Table>

                                                                <Space direction="vertical" style={{ marginTop: 30 }}>
                                                                    <label className="form-label" htmlFor="purchasedfrom">
                                                                        Protein: <strong>{getTotalProtiensCount(item.products)}</strong>, Carbs: <strong>{getTotalCarbsCount(item.products)}</strong>, Calories: <strong>{getTotalCaloriesCount(item.products)}</strong>, Fats: <strong>{getTotalFatsCount(item.products)}</strong>, Fibers: <strong>{getTotalFibersCount(item.products)}</strong>
                                                                    </label>
                                                                </Space>
                                                                <Divider />
                                                            </Space>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            {previewInstructions.map((item, index) => {
                                                return (
                                                    <div>
                                                        <Space direction="vertical">
                                                            <strong>{item.mealname}</strong>
                                                            <label className="form-label" htmlFor="purchasedfrom">
                                                                {item.instruction}
                                                            </label>
                                                            <Divider />
                                                        </Space>
                                                    </div>
                                                )
                                            })}
                                            {previewMeals.map((item, index) => {
                                                return (
                                                    <div>
                                                        {item.products.map((item, index) => {
                                                            return (
                                                                <Space direction="vertical">
                                                                    {item.subproductlist === "" ? <div></div>
                                                                        :
                                                                        <Space direction="vertical">
                                                                            <label className="form-label mt-5" htmlFor="purchasedfrom">
                                                                                Dish Name: <strong>{item.itemname}</strong>
                                                                            </label>
                                                                            <label className="form-label mt-1" htmlFor="purchasedfrom">
                                                                                <strong>Contents</strong>
                                                                            </label>
                                                                            <Table
                                                                                columns={[
                                                                                    {
                                                                                        title: "Product Name",
                                                                                        dataIndex: "itemname"
                                                                                    },
                                                                                    {
                                                                                        title: "Type",
                                                                                        dataIndex: "type"
                                                                                    },
                                                                                    {
                                                                                        title: "Quantity",
                                                                                        dataIndex: "quantity"
                                                                                    },
                                                                                ]}
                                                                                dataSource={JSON.parse(item.subproductlist)}
                                                                                pagination={false}>

                                                                            </Table>
                                                                            <label className="form-label mt-3" htmlFor="purchasedfrom">
                                                                                {item.receipe}
                                                                            </label>
                                                                        </Space>}
                                                                </Space>
                                                            )
                                                        })}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <h2>Add Diet Plan Here</h2>
                    <Space direction="horizontal" align="start">
                        <button className="btn btn-danger mt-3" onClick={() => {
                            setShowTimeModal(true)
                            setMealName("")
                            setSelectedQuantity("")
                        }}>Add Meal</button>
                        <button className="btn btn-danger mt-3" onClick={() => {
                            setShowInstructionModal(true)
                            setMealName("")
                            setInstruction("")
                        }}>Add Instructions</button>
                    </Space>
                    <Space direction="horizontal" align="start" >
                        <button className="btn btn-danger mt-3" onClick={() => setIsModalVisible(true)}>Save Diet Plan</button>
                        <button className="btn btn-danger mt-3" onClick={() => {
                            setIsModalVisible(true)
                            //console.log(meals)
                        }}>Print</button>
                    </Space>
                    <button className="btn btn-danger" onClick={() => {
                        //removeProduct(parseInt(data.id) - 1, index)
                        navigate('/')
                    }}>To Dashboard</button>

                    {/* <Table
                        columns={[
                            {
                                title: "Name",
                                dataIndex: "clientName"
                            },
                            {
                                title: "Mobile",
                                dataIndex: "clientMobile"
                            },
                            {
                                title: "Weight",
                                dataIndex: "weight"
                            },
                            {
                                title: "Height",
                                dataIndex: "height"
                            },
                            {
                                title: "Line of Work",
                                dataIndex: "lineofwork"
                            },
                            {
                                title: "Health Concern",
                                dataIndex: "healthconcern"
                            },
                            {
                                title: "Goal",
                                dataIndex: "goal"
                            },
                            {
                                title: "Date",
                                dataIndex: "plandate"
                            },
                            {
                                title: "Number of Meals",
                                dataIndex: "meals"
                            },
                            {
                                title: "",
                                dataIndex: "plandetails",
                                render: (value, data, index) => <button className="btn btn-danger" onClick={() => {
                                    //removeProduct(parseInt(data.id) - 1, index)
                                    var tempData = JSON.parse(data.plandetails);
                                    setPreviewMeals(tempData.meals)
                                    setPreviewInstructions(tempData.instructions)
                                    setIsPreviewModalVisible(true);
                                }}>Show Plan</button>
                            },
                        ]}
                        dataSource={displayDietPlans}>

                    </Table> */}
                    <label className="form-label mt-3" htmlFor="purchasedfrom">
                        <strong>Selected Client: {data.name}</strong>
                    </label>
                    {meals.length != 0 ? <Divider /> : <div></div>}
                    {meals.length != 0 ? <Space direction="horizontal" style={{ marginBottom: 20 }}>
                        <strong>New Diet Plan</strong>
                        <EmptyView />
                        <EmptyView />
                        <EmptyView />
                    </Space> : <div></div>}
                    <Space direction="vertical">
                        {meals.map((item, index) => {
                            return (
                                <div key={item}>
                                    <Space direction="vertical">
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            {item.mealname}
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Time: {item.time}
                                        </label>
                                        <button className="btn btn-danger mt-1 mb-1" onClick={() => {
                                            setShowProductsModal(true)
                                            setSelectedIndex(index)
                                        }}>Add Food</button>
                                        {!isMobile ?
                                            <Table
                                                columns={[
                                                    {
                                                        title: "Product Name",
                                                        dataIndex: "itemname"
                                                    },
                                                    {
                                                        title: "Type",
                                                        dataIndex: "type"
                                                    },
                                                    {
                                                        title: "Quantity",
                                                        dataIndex: "quantity"
                                                    },
                                                    {
                                                        title: "Protein",
                                                        dataIndex: "protein",
                                                        render: (value, data, index) => <p style={{ marginTop: 13 }}>{parseFloat(data.protein) * parseFloat(data.quantity)}</p>
                                                    },
                                                    {
                                                        title: "Fiber",
                                                        dataIndex: "fiber",
                                                        render: (value, data, index) => <p style={{ marginTop: 13 }}>{parseFloat(data.fiber) * parseFloat(data.quantity)}</p>
                                                    },
                                                    {
                                                        title: "Fats",
                                                        dataIndex: "fats",
                                                        render: (value, data, index) => <p style={{ marginTop: 13 }}>{parseFloat(data.fats) * parseFloat(data.quantity)}</p>
                                                    },
                                                    {
                                                        title: "Carbs",
                                                        dataIndex: "carbs",
                                                        render: (value, data, index) => <p style={{ marginTop: 13 }}>{parseFloat(data.carbs) * parseFloat(data.quantity)}</p>
                                                    },
                                                    {
                                                        title: "Calories",
                                                        dataIndex: "calories",
                                                        render: (value, data, index) => <p style={{ marginTop: 13 }}>{parseFloat(data.calories) * parseFloat(data.quantity)}</p>
                                                    },
                                                    {
                                                        title: "",
                                                        dataIndex: "itemname",
                                                        render: (value, data, insideIndex) => <button className="btn btn-danger" onClick={() => {
                                                            removeProduct(insideIndex, index)
                                                        }}>Remove</button>
                                                    },
                                                ]}
                                                dataSource={item.products}>

                                            </Table> :
                                            <Space direction="vertical">
                                                {item.products.map((item, insideIndex) => {
                                                    return (
                                                        <div key={item} style={{ borderRadius: "10px" }}>
                                                            <Card>
                                                                <Space direction="vertical" style={{ width: 200 }}>
                                                                    <label className="form-label" htmlFor="purchasedfrom">
                                                                        Name: <strong>{item.itemname}</strong>
                                                                    </label>
                                                                    <label className="form-label" htmlFor="purchasedfrom">
                                                                        Type: <strong>{item.type}</strong>
                                                                    </label>
                                                                    <label className="form-label" htmlFor="purchasedfrom">
                                                                        Quantity: <strong>{item.quantity}</strong>
                                                                    </label>
                                                                    <label className="form-label" htmlFor="purchasedfrom">
                                                                        Protein: <strong>{item.protein}</strong>, Carbs: <strong>{item.carbs}</strong>, Fats: <strong>{item.fats}</strong>, Fiber: <strong>{item.fiber}</strong>, Calories: <strong>{item.calories}</strong>
                                                                    </label>
                                                                    <button className="btn btn-danger" onClick={() => {
                                                                        removeProduct(insideIndex, index)
                                                                    }}>Remove</button>
                                                                </Space>
                                                            </Card>
                                                        </div>
                                                    )
                                                })}
                                            </Space>}
                                        <Space direction="vertical" style={{ maxWidth: '380px' }}>
                                            <label className="form-label" htmlFor="purchasedfrom">
                                                Protein :<strong>{getTotalProtiensCount(item.products)}</strong>, Fats: <strong>{getTotalFatsCount(item.products)}</strong>, Carbs: <strong>{getTotalCarbsCount(item.products)}</strong>, Fibers: <strong>{getTotalFibersCount(item.products)}</strong>, Calories: <strong>{getTotalCaloriesCount(item.products)}</strong>
                                            </label>
                                        </Space>
                                        <Divider />
                                    </Space>
                                </div>
                            )
                        })}
                        {instructions.map((item, index) => {
                            return (
                                <div>
                                    <Space direction="vertical">
                                        <strong>{item.mealname}</strong>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            {item.instruction}
                                        </label>
                                        <Divider />
                                    </Space>
                                </div>
                            )
                        })}
                        <EmptyView />
                        <EmptyView />
                        <EmptyView />
                    </Space>

                </Space>}
        </>
    )
}

export default AddDietPlan;