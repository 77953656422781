import { Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { reactLocalStorage } from 'reactjs-localstorage';
import { getIssues } from "../../API";

const ClientIssues = () => {

    const [trainerID, setTrainerID] = useState("");
    const [trainerName, setTrainerName] = useState("");
    const [clientIssues, setClientIssue] = useState([])

    useEffect(() => {
        var tempID = reactLocalStorage.get("id");
        var tempName = reactLocalStorage.get("trainername");
        setTrainerID(tempID);
        setTrainerName(tempName);
        getIssues(tempID)
        .then(res => {
            setClientIssue(res.Issues)
        })
    }, [])

    function EmptyView() {
        return (
            <Space>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </Space>
        )
    }

    

    return (
        <Space direction="vertical">
            <Space direction="horizontal">
                <Table
                    columns={[
                        {
                            title: "Name",
                            dataIndex: "clientName"
                        },
                        {
                            title: "Issues",
                            dataIndex: "issues"
                        },
                        {
                            title: "Medications",
                            dataIndex: "medications"
                        },
                        {
                            title: "",
                            dataIndex: "medications",
                            render: (value, data, index) => <button className="btn btn-danger" onClick={() => {
                                //removeProduct(parseInt(data.id) - 1, index)
                                //setIsPreviewModalVisible(true);
                            }}>Edit Issues</button>
                        },
                    ]}
                    dataSource={clientIssues}
                    pagination={false}>

                </Table>
                <EmptyView />
                <EmptyView />
                <EmptyView/>
                <EmptyView/>
            </Space>
        </Space>

    )
}

export default ClientIssues;