import { BASE_URL_PRODUCTION } from "../Utils/AppConstants";

export const getTrainerByLogin = (mobile, password) => {
    let formdata = new FormData();
    formdata.append("mobile", mobile);
    formdata.append("password", password);
    return fetch(BASE_URL_PRODUCTION + "getTrainerLogin.php", {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

export const getClients = (id) => {
    let formdata = new FormData();
    formdata.append("trainer_id", id);
    return fetch(BASE_URL_PRODUCTION + "getClient.php", {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

export const getClientByID = (id) => {
    let formdata = new FormData();
    formdata.append("id", id);
    return fetch(BASE_URL_PRODUCTION + "getClientByID.php", {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

export const addClients = (name, mobile, email, place, gender, dob, weight, height, lineofwork, healthconcern, allergyrestriction, healthgoal, sleepingtime, goal, trainerID, trainerName) => {
    let formdata = new FormData();
    formdata.append("name", name);
    formdata.append("mobile", mobile);
    formdata.append("email", email);
    formdata.append("place", place);
    formdata.append("gender", gender);
    formdata.append("dob", dob);
    formdata.append("weight", weight);
    formdata.append("height", height);
    formdata.append("lineofwork", lineofwork);
    formdata.append("healthconcern", healthconcern);
    formdata.append("allergyrestriction", allergyrestriction);
    formdata.append("healthgoal", healthgoal);
    formdata.append("sleepingtime", sleepingtime);
    formdata.append("goal", goal);
    formdata.append("trainer_id", trainerID);
    formdata.append("trainer_name", trainerName);
    formdata.append("value", name);
    formdata.append("label", name);
    return fetch(BASE_URL_PRODUCTION + "addClient.php", {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

export const addProducts = (itemname, type, protein, carbs, fats, fiber, calories, value, label, unit, subproductlist, receipe) => {
    let formdata = new FormData();
    formdata.append("itemname", itemname);
    formdata.append("type", type);
    formdata.append("protein", protein);
    formdata.append("carbs", carbs);
    formdata.append("fats", fats);
    formdata.append("fiber", fiber);
    formdata.append("calories", calories);
    formdata.append("unit", unit);
    formdata.append("subproductlist", subproductlist);
    formdata.append("receipe", receipe);
    formdata.append("value", value);
    formdata.append("label", label);
    return fetch(BASE_URL_PRODUCTION + "addProduct.php", {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

export const addExercise = (name, type, value, label) => {
    let formdata = new FormData();
    formdata.append("name", name);
    formdata.append("type", type);
    formdata.append("value", value);
    formdata.append("label", label);
    return fetch(BASE_URL_PRODUCTION + "addExercise.php", {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

export const getProducts = () => {
    let formdata = new FormData();
    formdata.append("mobile", "");
    return fetch(BASE_URL_PRODUCTION + "getProductsList.php", {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

export const getExercises = () => {
    let formdata = new FormData();
    formdata.append("mobile", "");
    return fetch(BASE_URL_PRODUCTION + "getExercises.php", {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

export const addDietPlan = (clientID, clientName, clientMobile, weight, height, lineofwork, healthconcern, sleepingtime, goal, plandate, meals, plandetails, trainer_id, trainer_name) => {
    let formdata = new FormData();
    formdata.append("clientID", clientID);
    formdata.append("clientName", clientName);
    formdata.append("clientMobile", clientMobile);
    formdata.append("weight", weight);
    formdata.append("height", height);
    formdata.append("lineofwork", lineofwork);
    formdata.append("healthconcern", healthconcern);
    formdata.append("sleepingtime", sleepingtime);
    formdata.append("goal", goal);
    formdata.append("plandate", plandate);
    formdata.append("meals", meals);
    formdata.append("plandetails", plandetails);
    formdata.append("trainer_id", trainer_id);
    formdata.append("trainer_name", trainer_name);
    return fetch(BASE_URL_PRODUCTION + "addDietPlan.php", {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

export const getClientWiseDietPlan = (trainer_id, clientID) => {
    let formdata = new FormData();
    formdata.append("trainer_id", trainer_id);
    formdata.append("clientID", clientID);
    return fetch(BASE_URL_PRODUCTION + "getClientWiseDietPlan.php", {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

export const getDietPlans = (trainer_id) => {
    let formdata = new FormData();
    formdata.append("trainer_id", trainer_id);
    return fetch(BASE_URL_PRODUCTION + "getDietPlans.php", {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

export const addWorkoutPlans = (clientID, clientName, clientMobile, name, type, goal, phase, workouts, trainer_id, trainer_name) => {
    let formdata = new FormData();
    formdata.append("clientID", clientID);
    formdata.append("clientName", clientName);
    formdata.append("clientMobile", clientMobile);
    formdata.append("name", name);
    formdata.append("type", type);
    formdata.append("goal", goal);
    formdata.append("phase", phase);
    formdata.append("workouts", workouts);
    formdata.append("trainer_id", trainer_id);
    formdata.append("trainer_name", trainer_name);
    return fetch(BASE_URL_PRODUCTION + "addWorkoutPlan.php", {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

export const getClientWiseWorkoutPlans = (trainer_id, clientID) => {
    let formdata = new FormData();
    formdata.append("trainer_id", trainer_id);
    formdata.append("clientID", clientID);
    return fetch(BASE_URL_PRODUCTION + "getClientWiseWorkoutPlan.php", {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

export const getWorkoutPlans = (trainer_id) => {
    let formdata = new FormData();
    formdata.append("trainer_id", trainer_id);
    return fetch(BASE_URL_PRODUCTION + "getWorkoutPlans.php", {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

export const addProgress = (clientID, clientName, clientMobile, height, weight, date, trainer_id, trainer_name) => {
    let formdata = new FormData();
    formdata.append("clientID", clientID);
    formdata.append("clientName", clientName);
    formdata.append("clientMobile", clientMobile);
    formdata.append("height", height);
    formdata.append("weight", weight);
    formdata.append("date", date);
    formdata.append("trainer_id", trainer_id);
    formdata.append("trainer_name", trainer_name);
    return fetch(BASE_URL_PRODUCTION + "addProgress.php", {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

export const getClientWiseProgress = (trainer_id, clientID) => {
    let formdata = new FormData();
    formdata.append("trainer_id", trainer_id);
    formdata.append("clientID", clientID);
    return fetch(BASE_URL_PRODUCTION + "getClientWiseProgress.php", {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

export const addIssues = (clientID, clientName, clientMobile, issues, medications, trainer_id, trainer_name) => {
    let formdata = new FormData();
    formdata.append("clientID", clientID);
    formdata.append("clientName", clientName);
    formdata.append("clientMobile", clientMobile);
    formdata.append("issues", issues);
    formdata.append("medications", medications);
    formdata.append("trainer_id", trainer_id);
    formdata.append("trainer_name", trainer_name);
    return fetch(BASE_URL_PRODUCTION + "addIssues.php", {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

export const getIssues = (trainer_id) => {
    let formdata = new FormData();
    formdata.append("trainer_id", trainer_id);
    return fetch(BASE_URL_PRODUCTION + "getIssues.php", {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

export const addAppointment = (clientID, clientName, clientMobile, date, time, task, charges, trainer_id, trainer_name) => {
    let formdata = new FormData();
    formdata.append("clientID", clientID);
    formdata.append("clientName", clientName);
    formdata.append("clientMobile", clientMobile);
    formdata.append("date", date);
    formdata.append("time", time);
    formdata.append("task", task);
    formdata.append("charges", charges);
    formdata.append("trainer_id", trainer_id);
    formdata.append("trainer_name", trainer_name);
    return fetch(BASE_URL_PRODUCTION + "addAppointment.php", {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

export const getAppointment = (trainer_id) => {
    let formdata = new FormData();
    formdata.append("trainer_id", trainer_id);
    return fetch(BASE_URL_PRODUCTION + "getAppointments.php", {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}

export const addEnquiry = (date, name, mobile, purpose) => {
    let formdata = new FormData();
    formdata.append("date", date);
    formdata.append("name", name);
    formdata.append("mobile", mobile);
    formdata.append("purpose", purpose);
    return fetch(BASE_URL_PRODUCTION + "addEnquiries.php", {
        method: 'POST',
        body: formdata
    })
        .then(res => res.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            console.error(error);
        });
}