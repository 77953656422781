import { Input, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { addClients } from "../../API";
import {reactLocalStorage} from 'reactjs-localstorage';
import { useNavigate } from "react-router-dom";

const AddClient = () => {

    const navigate = useNavigate();
    const [clientName, setClientName] = useState('');
    const [clientMobile, setClientMobile] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [clientAge, setClientAge] = useState('');
    const [clientGender, setClientGender] = useState('');
    const [clientPlace, setClientPlace] = useState('');
    const [clientBodyWeight, setClientBodyWeight] = useState('');
    const [clientHeight, setClientHeight] = useState('');
    const [clientLineOfWork, setClientLineOfWork] = useState('');
    const [clientHealthConcern, setClientHealthConcern] = useState('');
    const [clientAllergyRestriction, setClientAllergyRestriction] = useState('');
    const [clientHealthGoal, setClientHealthGoal] = useState('');
    const [clientSleepingTime, setClientSleepingTime] = useState('');
    const [clientGoal, setClientGoal] = useState('');
    const [trainerID, setTrainerID] = useState("");
    const [trainerName, setTrainerName] = useState("");

    useEffect(() => {
        var tempID = reactLocalStorage.get("id");
        var tempName = reactLocalStorage.get("trainername");
        setTrainerID(tempID);
        setTrainerName(tempName);
        console.log(tempID);
    }, [])

    function EmptyView() {
        return (
            <Space>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </Space>
        )
    }

    const AddClientData = () => {
        if (clientName === "") {
            alert("Please enter client Name.")
        } else if (clientMobile === "") {
            alert("Please enter client Mobile.")
        } else if (clientEmail === "") {
            alert("Please enter client Email.")
        } else if (clientAge === "") {
            alert("Please enter client Date of Birth.")
        } else if (clientGender === "") {
            alert("Please enter client Gender.")
        } else if (clientPlace === "") {
            alert("Please enter client Place.")
        } else if (clientHeight === "") {
            alert("Please enter client Height.")
        } else if (clientBodyWeight === "") {
            alert("Please enter client Body Weight.")
        } else if (clientLineOfWork === "") {
            alert("Please enter client Line of Work.")
        } else if (clientHealthConcern === "") {
            alert("Please enter client Health Concern.")
        } else if (clientAllergyRestriction === "") {
            alert("Please enter client Allergy Restriction.")
        } else if (clientHealthGoal === "") {
            alert("Please enter client Health Goal.")
        } else if (clientSleepingTime === "") {
            alert("Please enter client Sleeping Time.")
        } else if (clientGoal === "") {
            alert("Please enter client Goal.")
        } else {
            addClients(clientName, clientMobile, clientEmail, clientPlace, clientGender, clientAge, clientBodyWeight, clientHeight, clientLineOfWork, clientHealthConcern, clientAllergyRestriction, clientHealthGoal, clientSleepingTime, clientGoal, trainerID, trainerName)
            .then(res => {
                alert("Client Added Successfully.");
                navigate('/clientlist')
            })
        }
    }

    return (
        <>
            <Space>
                <Space direction="horizontal">
                    <div className="container mt-5" style={{ flex: 1 }}>
                        <h2 className="mb-3">Add Client Details</h2>
                        {/* <form onSubmit={onSubmit}> */}
                        <Space direction="vertical">
                            <Space direction="horizontal">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="title">
                                        Name
                                    </label>
                                    <Input value={clientName} className="form-control" id="quantity" required onChange={(evt) => { setClientName(evt.target.value) }} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="price">
                                        Mobile
                                    </label>
                                    <Input value={clientMobile} className="form-control" type="number" id="quantity" required onChange={(evt) => { setClientMobile(evt.target.value) }} />
                                </div>
                            </Space>
                            <Space direction="horizontal">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="title">
                                        Email
                                    </label>
                                    <Input value={clientEmail} className="form-control" id="quantity" required onChange={(evt) => { setClientEmail(evt.target.value) }} />
                                </div>
                            </Space>
                            <Space direction="horizontal">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="title">
                                        Date of Birth
                                    </label>
                                    <Input value={clientAge} className="form-control" id="quantity" required onChange={(evt) => { setClientAge(evt.target.value) }} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="price">
                                        Gender
                                    </label>
                                    <Input value={clientGender} className="form-control" id="quantity" required onChange={(evt) => { setClientGender(evt.target.value) }} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="price">
                                        Place
                                    </label>
                                    <Input value={clientPlace} className="form-control" id="quantity" required onChange={(evt) => { setClientPlace(evt.target.value) }} />
                                </div>
                            </Space>
                            <Space direction="horizontal">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="title">
                                        Height
                                    </label>
                                    <Input value={clientHeight} className="form-control" id="quantity" required onChange={(evt) => { setClientHeight(evt.target.value) }} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="price">
                                        Body Weight
                                    </label>
                                    <Input value={clientBodyWeight} className="form-control" id="quantity" required onChange={(evt) => { setClientBodyWeight(evt.target.value) }} />
                                </div>
                            </Space>
                            <Space direction="horizontal">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="title">
                                        Line of Work
                                    </label>
                                    <Input value={clientLineOfWork} className="form-control" id="quantity" required onChange={(evt) => { setClientLineOfWork(evt.target.value) }} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="price">
                                        Health Concern
                                    </label>
                                    <Input value={clientHealthConcern} className="form-control" id="quantity" required onChange={(evt) => { setClientHealthConcern(evt.target.value) }} />
                                </div>
                            </Space>
                            <Space direction="horizontal">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="title">
                                        Allergy Restriction
                                    </label>
                                    <Input value={clientAllergyRestriction} className="form-control" id="quantity" required onChange={(evt) => { setClientAllergyRestriction(evt.target.value) }} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="price">
                                        Health Goal
                                    </label>
                                    <Input value={clientHealthGoal} className="form-control" id="quantity" required onChange={(evt) => { setClientHealthGoal(evt.target.value) }} />
                                </div>
                            </Space>
                            <Space direction="horizontal">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="title">
                                        Sleeping Time
                                    </label>
                                    <Input value={clientSleepingTime} className="form-control" id="quantity" required onChange={(evt) => { setClientSleepingTime(evt.target.value) }} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="price">
                                        Goal
                                    </label>
                                    <Input value={clientGoal} className="form-control" id="quantity" required onChange={(evt) => { setClientGoal(evt.target.value) }} />
                                </div>
                            </Space>
                            <button className="btn btn-danger mb-5" onClick={() => AddClientData()}>
                                Add Client Details
                            </button>
                        </Space>

                    </div>
                    <EmptyView />
                    <EmptyView />
                    <EmptyView />
                    <EmptyView />
                </Space>
            </Space>
        </>
    )
}

export default AddClient;