import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "../../Pages/Dashboard";
import AddClient from "../../Pages/AddClient";
import ClientList from "../../Pages/ClientList";
import ProductList from "../../Pages/ProductsList";
import DietPlans from "../../Pages/DietPlans";
import ProgressList from "../../Pages/ProgressList";
import AddProducts from "../../Pages/AddProducts";
import AddDietPlan from "../../Pages/AddDietPlan";
import WorkoutList from "../../Pages/WorkoutList";
import ClientDetails from "../../Pages/ClientDetails";
import Exercises from "../../Pages/Exercises";
import AddWorkoutPlan from "../../Pages/AddWorkoutPlan";
import ClientIssues from "../../Pages/ClientIssues";
import AddDishes from "../../Pages/AddDishes";

function AppRoutes() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Dashboard />}></Route>
                <Route path="/addclient" element={<AddClient />}></Route>
                <Route path="/adddishes" element={<AddDishes />}></Route>
                <Route path="/clientlist" element={<ClientList />}></Route>
                <Route path="/addproducts" element={<AddProducts />}></Route>
                <Route path="/productlist" element={<ProductList />}></Route>
                <Route path="/adddietplan" element={<AddDietPlan />}></Route>
                <Route path="/dietplans" element={<DietPlans />}></Route>
                <Route path="/progresslist" element={<ProgressList />}></Route>
                <Route path="/exercises" element={<Exercises />}></Route>
                <Route path="/workoutlist" element={<WorkoutList />}></Route>
                <Route path="/addworkoutplan" element={<AddWorkoutPlan />}></Route>
                <Route path="/clientdetails" element={<ClientDetails />}></Route>
                <Route path="/clientissues" element={<ClientIssues />}></Route>
            </Routes>
        </div>
            
    )
}

export default AppRoutes;