import { Badge, Image, Space, Typography } from "antd";
import { MailOutlined, BellFilled } from '@ant-design/icons';

function AppHeader() {
    return (
        <div className="AppHeader my-3">
            <Space direction="horizontal">
                {/* <Image
                    width={40}
                    src="https://yt3.ggpht.com/ytc/AL5GRJVbE2u7nMjJ6Wi3XThhqPhAUsVnXgz9RzZOLPTL=s88-c-k-c0x00ffffff-no-rj">
                </Image> */}
                <Typography.Title>Fitness App</Typography.Title>
                <Space>
                    <Badge >
                        {/* <MailOutlined style={{ fontSize: 24 }} /> */}
                    </Badge>

                </Space>
            </Space>
        </div>
    )
}

export default AppHeader;