import { DollarCircleOutlined, FieldTimeOutlined, ShoppingCartOutlined, UserOutlined } from "@ant-design/icons";
import { Card, Space, Statistic, Typography, Table, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useNavigate } from "react-router-dom";
import { reactLocalStorage } from 'reactjs-localstorage';
import { getAppointment, getClients } from "../../API";
import useWindowDimensions from "../../Components/useWindowDimensions";

const Dashboard = () => {

    const { height, width } = useWindowDimensions();
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false)
    const [clients, setClients] = useState([
        {
            id: 1,
            customerName: "Sushant Mali",
            place: "Thane",
            gender: "Male",
            mobile: "8928765934",
            age: "31",
            bodyweight: "90 KG",
            height: "5.7 Feet",
            lineofwork: "IT",
            healthconcern: "Asthama",
            allergyrestriction: "Peanuts",
            healthgoal: "Athletisism",
            sleepingtime: "11:00 PM",
            goal: "Achieve flexible body",
            weight: "90",
            height: "170"
        },
        {
            id: 2,
            customerName: "Mitali Mali",
            place: "Thane",
            gender: "Female",
            mobile: "9860211649",
            age: "29",
            bodyweight: "114 KG",
            height: "5.7 Feet",
            lineofwork: "IT",
            healthconcern: "Thyroid",
            allergyrestriction: "Mushroom",
            healthgoal: "Athletisism",
            sleepingtime: "11:30 PM",
            goal: "Achieve flexible body",
            weight: "114",
            height: "170"
        }
    ])
    const [recentClients, setRecentClients] = useState([
        {
            clientid: 1,
            customerName: "Sushant Mali",
            place: "Thane",
            gender: "Male",
            mobile: "8928765934",
            age: "31",
            bodyweight: "90 KG",
            height: "5.7 Feet",
            lineofwork: "IT",
            healthconcern: "Asthama",
            allergyrestriction: "Peanuts",
            healthgoal: "Athletisism",
            sleepingtime: "11:00 PM",
            goal: "Achieve flexible body",
            weight: "90",
            height: "170",
            appointmentdate: "23 July 2024",
            appointmenttime: "4:30 PM",
            task: "Diet Plan"
        },
        {
            clientid: 2,
            customerName: "Mitali Mali",
            place: "Thane",
            gender: "Female",
            mobile: "9860211649",
            age: "29",
            bodyweight: "114 KG",
            height: "5.7 Feet",
            lineofwork: "IT",
            healthconcern: "Thyroid",
            allergyrestriction: "Mushroom",
            healthgoal: "Athletisism",
            sleepingtime: "11:30 PM",
            goal: "Achieve flexible body",
            weight: "114",
            height: "170",
            appointmentdate: "23 July 2024",
            appointmenttime: "10:00 AM",
            task: "Workout Plan"
        },
        {
            clientid: 2,
            customerName: "Varad Duble",
            place: "Thane",
            gender: "Female",
            mobile: "9860211649",
            age: "29",
            bodyweight: "125 KG",
            height: "6.0 Feet",
            lineofwork: "IT",
            healthconcern: "Thyroid",
            allergyrestriction: "Mushroom",
            healthgoal: "Athletisism",
            sleepingtime: "11:30 PM",
            goal: "Achieve flexible body",
            weight: "125",
            height: "185",
            appointmentdate: "23 July 2024",
            appointmenttime: "10:00 AM",
            task: "Workout Plan"
        },
    ])
    const [consultations, setConsultations] = useState([]);
    const [earnings, setEarnings] = useState(55300);
    const [trainerID, setTrainerID] = useState("");
    const [trainerName, setTrainerName] = useState("");

    function DashboardCard({ title, value, icon }) {
        return (
            <Card>
                <Space direction="horizontal">
                    {icon}
                    <Statistic title={title} value={value}></Statistic>
                </Space>
            </Card>
        )
    }

    function EmptyView() {
        return (
            <Space>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </Space>
        )
    }

    function SmallEmptyView() {
        return (
            <Space>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </Space>
        )
    }

    function BarChart({ lastTolastMonthRevenue, lastmonthRevenue, currentMonthRevenue }) {

        const data = [
            ["Month", "May", "June", "July"],
            [new Date().getFullYear(), lastTolastMonthRevenue, lastmonthRevenue, currentMonthRevenue],
        ];

        const options = {
            chart: {
                title: "Revenue",
                subtitle: "Last Three Months Earnings",
            },
        };

        return (
            <Chart
                chartType="Bar"
                width="100%"
                height="400px"
                marginLeft="230px"
                data={data}
                options={options}
            />
        )
    }

    useEffect(() => {
        console.log("Screen Width: " + width);
    }, [])

    useEffect(() => {
        var tempID = reactLocalStorage.get("id");
        var tempName = reactLocalStorage.get("trainername");
        var isMobile = reactLocalStorage.get("isMobile");
        setTrainerID(tempID);
        setTrainerName(tempName);
        if (isMobile === "true") {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
        getAppointment(tempID)
            .then(res => {
                setRecentClients(res.Appointments)
                var tempEarnings = 0
                for (var i = 0; i < res.Appointments.length; i++) {
                    tempEarnings = tempEarnings + parseInt(res.Appointments[i].charges)
                }
                setEarnings(tempEarnings);
                getClients(tempID)
                    .then(res => {
                        setClients(res.Clients);
                    })
            })
    }, [])

    return (
        <Space size={12} direction="vertical">
            <Space direction="horizontal">
                <DashboardCard
                    icon={<FieldTimeOutlined
                        style={{
                            color: 'green',
                            backgroundColor: 'rgba(0, 255, 0, 0.5)',
                            borderRadius: 20,
                            fontSize: 24,
                            padding: 8
                        }} />}
                    title="Consultations"
                    value={recentClients.length} />
                <DashboardCard
                    icon={<UserOutlined
                        style={{
                            color: 'purple',
                            backgroundColor: 'rgba(0, 255, 255, 0.5)',
                            borderRadius: 20,
                            fontSize: 24,
                            padding: 8
                        }} />}
                    title="Customers"
                    value={clients.length} />
                <DashboardCard
                    icon={<DollarCircleOutlined
                        style={{
                            color: 'red',
                            backgroundColor: 'rgba(255, 0, 0, 0.25)',
                            borderRadius: 20,
                            fontSize: 24,
                            padding: 8
                        }} />}
                    title="Revenue"
                    value={earnings} />
                <EmptyView />
                <EmptyView />
                <EmptyView />
            </Space>
            <Space direction="vertical">
                {!isMobile ? <div></div> :
                    <Space direction="vertical">
                        <Space direction="horizontal">
                            <button className="btn btn-danger" onClick={() => {
                                //removeProduct(parseInt(data.id) - 1, index)
                                navigate('/clientlist')
                            }}>Client List</button>
                            <button className="btn btn-danger" onClick={() => {
                                //removeProduct(parseInt(data.id) - 1, index)
                                navigate('/productlist')
                            }}>Products</button>
                            <button className="btn btn-danger" onClick={() => {
                                //removeProduct(parseInt(data.id) - 1, index)
                                navigate('/adddishes')
                            }}>Add Dishes</button>
                        </Space>
                        <Space direction="horizontal">
                            <button className="btn btn-danger" onClick={() => {
                                //removeProduct(parseInt(data.id) - 1, index)
                                navigate('/dietplans')
                            }}>Diet Plan List</button>
                            <button className="btn btn-danger" onClick={() => {
                                //removeProduct(parseInt(data.id) - 1, index)
                                navigate('/workoutlist')
                            }}>Workout Plan List</button>
                        </Space>
                    </Space>
                }
                <Space direction="horizontal" style={{ marginTop: 10 }}>
                    <strong>Recent Appointments Conducted</strong>
                    <EmptyView />
                    <EmptyView />
                    <EmptyView />
                    <EmptyView />
                    <SmallEmptyView />
                </Space>
                <Space direction="horizontal">
                    {!isMobile ?
                        <Table
                            columns={[
                                {
                                    title: "Person Name",
                                    dataIndex: "clientName",
                                },
                                {
                                    title: "Appointment Date",
                                    dataIndex: "date",
                                },
                                {
                                    title: "Appointment Time",
                                    dataIndex: "time",
                                },
                                {
                                    title: "Task",
                                    dataIndex: "task",
                                },
                                {
                                    title: "",
                                    dataIndex: "task",
                                    render: (value, data, index) => <button className="btn btn-danger" onClick={() => {
                                        //removeProduct(parseInt(data.id) - 1, index)
                                        navigate('/clientdetails', { state: { data: data } })
                                    }}>Show Details</button>
                                },
                            ]}
                            dataSource={recentClients}>

                        </Table> :
                        <Space direction="vertical">
                            {recentClients.map((item, index) => {
                                return (
                                    <div key={item} style={{ borderRadius: "10px" }}>
                                        <Card>
                                            <Space direction="vertical">
                                                <label className="form-label" htmlFor="purchasedfrom">
                                                    <strong>{item.clientName}</strong>
                                                </label>
                                                <label className="form-label" htmlFor="purchasedfrom">
                                                    Appointment: <strong>{item.date} {item.time}</strong>
                                                </label>
                                                <label className="form-label" htmlFor="purchasedfrom">
                                                    Task: <strong>{item.task}</strong>
                                                </label>
                                                <button className="btn btn-danger" onClick={() => {
                                                    //removeProduct(parseInt(data.id) - 1, index)
                                                    navigate('/clientdetails', { state: { data: item } })
                                                }}>Show Details</button>
                                            </Space>
                                        </Card>
                                    </div>
                                )
                            })}
                        </Space>
                    }
                    {/*  */}
                    {/* <SmallEmptyView />
                    <BarChart lastTolastMonthRevenue={44500} lastmonthRevenue={41200} currentMonthRevenue={48000} />
                    <SmallEmptyView /> */}
                    <EmptyView />
                    <EmptyView />
                    <SmallEmptyView />
                </Space>
            </Space>

        </Space>
    )
}

export default Dashboard;