import { Menu } from 'antd';
import { AppstoreAddOutlined, ShopOutlined, ShoppingCartOutlined, UserOutlined, DeleteOutlined, DollarCircleOutlined, ProductOutlined, BookOutlined, PercentageOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

function SideMenu() {
    const navigate = useNavigate();
    return (
        <div className="SideMenu">
            <Menu
                onClick={(item) => {
                    //item.key
                    navigate(item.key);
                }}
                items={[
                    {
                        label: "Dasboard",
                        icon: <AppstoreAddOutlined />,
                        key: "/"
                    },
                    {
                        label: "Clients",
                        icon: <UserOutlined />,
                        key: "/clientlist"
                    },
                    {
                        label: "Products",
                        icon: <ProductOutlined />,
                        key: "/productlist"
                    },
                    {
                        label: "Add Dishes",
                        icon: <ProductOutlined />,
                        key: "/adddishes"
                    },
                    {
                        label: "Diet Plans",
                        icon: <BookOutlined />,
                        key: "/dietplans"
                    },
                    {
                        label: "Progress",
                        icon: <PercentageOutlined />,
                        key: "/progresslist"
                    },
                    {
                        label: "Exercises",
                        icon: <BookOutlined />,
                        key: "/exercises"
                    },
                    {
                        label: "Workouts",
                        icon: <BookOutlined />,
                        key: "/workoutlist"
                    },
                    {
                        label: "Client Issues",
                        icon: <BookOutlined />,
                        key: "/clientissues"
                    },
                ]}>

            </Menu>
        </div>
    )
}

export default SideMenu;