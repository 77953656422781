import { Space, Table, Modal, Input, Card } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { reactLocalStorage } from 'reactjs-localstorage';
import { addIssues, getClients } from "../../API";
import useWindowDimensions from "../../Components/useWindowDimensions";

const ClientList = () => {

    const { height, width } = useWindowDimensions();
    const [trainerID, setTrainerID] = useState("");
    const [trainerName, setTrainerName] = useState("");
    const navigate = useNavigate();
    const [clients, setClients] = useState([])
    const [loading, setLoading] = useState(false);
    const [showClientIssues, setShowClientIssues] = useState(false);


    const [selectedClientName, setSelectedClientName] = useState("");
    const [selectedClientMobile, setSelectedClientMobile] = useState("");
    const [selectedClientID, setSelectedClientID] = useState("");

    const [selectedIssue, setSelectedIssue] = useState("");
    const [selectedMedication, setSelectedMedication] = useState("");
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        var tempID = reactLocalStorage.get("id");
        var tempName = reactLocalStorage.get("trainername");
        var isMobile = reactLocalStorage.get("isMobile");
        setTrainerID(tempID);
        setTrainerName(tempName);
        if (isMobile === "true") {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
        getClients(tempID)
            .then(res => {
                setClients(res.Clients)
            })
    }, [])

    const AddIssueData = (clientID, clientName, clientMobile) => {
        if (selectedIssue === "") {
            alert("Please add issue properly")
        } else if (selectedMedication === "") {
            alert("Please add medications properly.")
        } else {
            addIssues(clientID, clientName, clientMobile, selectedIssue, selectedMedication, trainerID, trainerName)
                .then(res => {
                    alert("Medical Issue Added Successfully.");
                    setSelectedIssue("");
                    setSelectedMedication("");
                    setShowClientIssues(false);
                })
        }
    }

    return (
        <Space size={20} direction="vertical" align="start">
            <Link to="/addclient" className="btn btn-danger" type="submit">
                Add Client
            </Link>
            {!isMobile ?
                <Table
                    columns={[
                        // {
                        //     title: "Image",
                        //     dataIndex: "image",
                        //     render: (link) => {
                        //         return <Avatar src={link} />
                        //     }
                        // },
                        {
                            title: "",
                            dataIndex: "billdata",
                            render: (value, data) => <button className="btn btn-danger" onClick={() => {
                                //navigate('/customerorders')
                                navigate('/clientdetails', { state: { data: data } })
                            }}>Show Details</button>
                        },
                        {
                            title: "",
                            dataIndex: "billdata",
                            render: (value, data) => <button className="btn btn-danger" onClick={() => {
                                navigate('/adddietplan', { state: { data: data } })

                            }}>Add Diet Plan</button>
                        },
                        {
                            title: "",
                            dataIndex: "billdata",
                            render: (value, data) => <button className="btn btn-danger" onClick={() => {
                                navigate('/addworkoutplan', { state: { data: data } })

                            }}>Add Workout Plan</button>
                        },
                        {
                            title: "",
                            dataIndex: "billdata",
                            render: (value, data) => <button className="btn btn-danger" onClick={() => {
                                //navigate('/addworkoutplan',  { state: { data: data }})
                                setSelectedClientID(data.id);
                                setSelectedClientName(data.name);
                                setSelectedClientMobile(data.mobile);
                                setShowClientIssues(true);
                            }}>Add Medical Issue</button>
                        },
                        {
                            title: "Customer Name",
                            dataIndex: "name"
                        },
                        {
                            title: "Phone",
                            dataIndex: "mobile"
                        },
                        {
                            title: "Place",
                            dataIndex: "place"
                        },
                        {
                            title: "Gender",
                            dataIndex: "gender"
                        },
                        {
                            title: "Age",
                            dataIndex: "age"
                        },
                        {
                            title: "Body Weight",
                            dataIndex: "weight"
                        },
                        {
                            title: "Height",
                            dataIndex: "height"
                        },
                        {
                            title: "Line of Work",
                            dataIndex: "lineofwork"
                        },
                        {
                            title: "Health Concern",
                            dataIndex: "healthconcern"
                        },
                        {
                            title: "Allergy Restriction",
                            dataIndex: "allergyrestriction"
                        },
                        {
                            title: "Health Goal",
                            dataIndex: "healthgoal"
                        },
                        {
                            title: "Sleeping Time",
                            dataIndex: "sleepingtime"
                        },
                        {
                            title: "Goal",
                            dataIndex: "goal"
                        },

                    ]}
                    loading={loading}
                    dataSource={clients}
                    pagination={{
                        pageSize: 5
                    }}>

                </Table> :
                <Space direction="vertical" style={{ marginBottom: "100px" }}>
                    {clients.map((item, index) => {
                        return (
                            <div key={item} style={{ borderRadius: "10px" }}>
                                <Card>
                                    <Space direction="vertical">
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            <strong>{item.name}</strong>
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Mobile: <strong>{item.mobile}</strong>
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Place: <strong>{item.place}</strong>
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Gender: <strong>{item.gender}</strong>
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Age: <strong>{item.age}</strong>
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Weight: <strong>{item.weight}</strong>
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Height: <strong>{item.weight}</strong>
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Line of Work: <strong>{item.lineofwork}</strong>
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Health Concern: <strong>{item.healthconcern}</strong> 
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Allergy Restriction: <strong>{item.allergyrestriction}</strong>
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Health Goal: <strong>{item.healthgoal}</strong> 
                                        </label>
                                        <label className="form-label" htmlFor="purchasedfrom">
                                            Sleeping Time: <strong>{item.sleepingtime}</strong>
                                        </label>
                                        <Space direction="horizontal">
                                            <button className="btn btn-danger" onClick={() => {
                                                //navigate('/customerorders')
                                                navigate('/clientdetails', { state: { data: item } })
                                            }}>Show Details</button>
                                            <button className="btn btn-danger" onClick={() => {
                                                navigate('/adddietplan', { state: { data: item } })

                                            }}>Add Diet Plan</button>
                                        </Space>
                                        <Space direction="horizontal">
                                            <button className="btn btn-danger" onClick={() => {
                                                navigate('/addworkoutplan', { state: { data: item } })

                                            }}>Add Workout Plan</button>
                                            <button className="btn btn-danger" onClick={() => {
                                                //navigate('/addworkoutplan',  { state: { data: data }})
                                                setSelectedClientID(item.id);
                                                setSelectedClientName(item.name);
                                                setSelectedClientMobile(item.mobile);
                                                setShowClientIssues(true);
                                            }}>Add Medical Issue</button>
                                        </Space>
                                    </Space>
                                </Card>
                            </div>
                        )
                    })}
                </Space>}
            <Modal
                visible={showClientIssues}
                okText="Save"
                closable={false}
                cancelText="Close"
                onOk={() => {
                    //AddFoodToMeal(selectedIndex, selectedQuantity, selectedProduct)
                    //AddInstructions(mealname, instruction);
                    AddIssueData(selectedClientID, selectedClientName, selectedClientMobile)
                    setShowClientIssues(false);
                }}
                onCancel={() => {
                    setShowClientIssues(false);
                }}>
                <div>
                    <div className="mb-3">
                        <Space direction="vertical">
                            <label className="form-label" htmlFor="stock">
                                Set Issue
                            </label>
                            <Input value={selectedIssue} className="form-control" id="stock" required onChange={(evt) => {
                                //setEditedPrice(evt.target.value) 
                                setSelectedIssue(evt.target.value)
                            }} />
                            <label className="form-label" htmlFor="stock">
                                Set Medication
                            </label>
                            <Input value={selectedMedication} className="form-control" id="stock" required onChange={(evt) => {
                                //setEditedPrice(evt.target.value) 
                                setSelectedMedication(evt.target.value)
                            }} />
                        </Space>
                    </div>
                </div>
            </Modal>
        </Space>
    )
}

export default ClientList;